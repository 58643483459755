import React, {Component} from "react";
import {Grid, Row, Col, FormGroup, ControlLabel, FormControl} from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import Switch from "react-bootstrap-switch";
import axios from "axios/index";
import { css } from "@emotion/core";
import {ScaleLoader} from 'react-spinners';
import {Redirect} from "react-router";
import Button from "../../components/CustomButton/CustomButton";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import ModalSave from "../../components/CustomModals/ModalSave";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import NotificationSystem from "react-notification-system";
import {style} from "../../variables/Variables";

const override = css`
    position: fixed;
    left: 50%;
    top: 35%;
    display: block;
    z-index: 1000;
    height: 31px;
    width: 100px;
    `;

class LiveChannelsCategoryEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {

            loading: false,
            redirect: false,
            channelCatRedirect: false,
            back_button_text: "Cancel",
            showSaveModal: false,

            categoryObject: [],
            cat_id: 0,
            cat_status: false,
            cat_position: 0,
            cat_name_short: "",
            cat_name_long: "",
            cat_description: "",
            cat_image: "",

            cat_image_temp: false,
            cat_image_folder_temp: "",

            _notificationSystem: null
        };

        // this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChildImageSelect = this.handleChildImageSelect.bind(this);

    }

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    renderRedirect = () => {

        let path = "/categories/channels";
        let pathCategory = "/categories/edit-tv-cat/" + this.state.cat_id;

        if (this.state.redirect) {
            return <Redirect push to={path}/>
        }

        if (this.state.channelCatRedirect) {
            //console.log(pathCategory);
            return <Redirect push to={pathCategory}/>
        }

    }


    submitCatImage(filename) {

        if (this.state.cat_image_temp) {
            const url = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.cat_image_folder_temp);
            bodyFormData.set('location', "live_tv_cat");
            bodyFormData.set('filename', filename);

            axios.post(url, bodyFormData)
                .then((res) => {
                    this.setState({cat_image_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }

    }

    handleChildImageSelect(folder, filename) {

        console.log("" + folder);
        console.log("" + filename);
        console.log(process.env.REACT_APP_ASSET_URL + "/tmp/" + folder + "/" + filename);

        this.setState({
            cat_image_temp: filename !== null,
            cat_image: filename,
            cat_image_folder_temp: folder
        });
    }

    componentDidMount() {

        this.setState({_notificationSystem: this.refs.notificationSystem})
        this.setState({loading: true})

        window.scroll(0, 0)

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        const {handle} = this.props.match.params
        const url = process.env.REACT_APP_API_URL + "/LiveCategories/" + handle;


        if (handle === 'new') {
            this.setState({loading: false})
            this.getPosition()
        } else {

            axios
                .get(url, axiosConfig)
                .then(res => {

                    const categoryObject = res.data[0];
                    const loading = false;

                    this.setState({
                        back_button_text: "Back",
                        cat_id: categoryObject.cat_id,
                        cat_status: categoryObject.cat_status === 0 ? false : true,
                        cat_position: categoryObject.cat_position === null ? 0 : categoryObject.cat_position,
                        cat_name_short: categoryObject.cat_name_short === null ? "" : categoryObject.cat_name_short,
                        cat_name_long: categoryObject.cat_name_long === null ? "" : categoryObject.cat_name_long,
                        cat_description: categoryObject.cat_description === null ? "" : categoryObject.cat_description,
                        cat_image: categoryObject.cat_image === null ? "" : categoryObject.cat_image,
                        loading,
                        categoryObject
                    });
                })
                .catch((err) => {
                    console.log("ERROR: ", err);
                    this.setState({loading: false})
                    //this.props.dispatch(userActions.logoutUnauthorized());
                })
        }
    }

    getPosition() {

        const url = process.env.REACT_APP_API_URL + "/LiveCategories/position";

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        axios
            .get(url, axiosConfig)
            .then(res => {

                const channelCatPosition = res.data[0];

                this.setState({
                    cat_position: channelCatPosition.LiveCatPosition

                });
            });

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'statusSwitch' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    updateLiveCategory() {

        //this.submitPosterImage();
        this.setState({loading: true})

        const {handle} = this.props.match.params
        const url = process.env.REACT_APP_API_URL + "/LiveCategories/" + handle;

        let filename = ""

        if (this.state.cat_image_temp) {
            let timeUTC = new Date().getTime()
            filename = timeUTC + "_" + this.state.cat_image
            this.setState({cat_image: filename})
            //console.log("*****" + filename)
            this.submitCatImage(filename);
        } else {
            filename = this.state.cat_image
        }

        // Send a PUT request
        var postData = {
            live_category: {
                cat_id: handle,
                cat_status: this.state.cat_status,
                cat_position: this.state.cat_position,
                cat_name_short: this.state.cat_name_short,
                cat_name_long: this.state.cat_name_long,
                cat_description: this.state.cat_description,
                cat_image: filename
            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            }
        };

        axios.put(url, postData, axiosConfig)
            .then((res) => {
                this.setState({loading: false})

            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.setState({loading: false})
                //this.props.dispatch(userActions.logoutUnauthorized());
            })

    }

    createLiveCategory() {

        const url = process.env.REACT_APP_API_URL + "/LiveCategories";
        this.setState({loading: true})

        let filename = ""

        if (this.state.cat_image_temp) {
            let timeUTC = new Date().getTime()
            filename = timeUTC + "_" + this.state.cat_image
            this.setState({cat_image: filename})
            console.log("*****" + filename)
            //this.submitCatImage(filename);
        } else {
            filename = this.state.cat_image
        }

        var postData = {
            live_category: {
                cat_status: this.state.cat_status,
                cat_position: this.state.cat_position,
                cat_name_short: this.state.cat_name_short,
                cat_name_long: this.state.cat_name_long,
                cat_description: this.state.cat_description,
                cat_image: filename
            }
        };


        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            }
        };

        axios.post(url, postData, axiosConfig)
            .then((res) => {
                this.setState({loading: false})
                this.submitCatImage();

                if (res.data.code === 300) {

                }

                const categoryObject = res.data;
                this.setState({cat_id: categoryObject.cat_id})
                this.setChannelCatRedirect();
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.setState({loading: false})
                //this.props.dispatch(userActions.logoutUnauthorized());
            })

    }

    getValidationState() {
        const length = this.state.cat_name_short.length;
        if (length > 0) {
            return 'success';
        } else {
            return 'error';
        }

    }

    setSaveModal = (value, action) => {

        this.setState({
            showSaveModal: value

        })

        if (action === "save") {
            this.setState({loading: true})

            const {handle} = this.props.match.params

            if (handle === 'new') {
                this.createLiveCategory();
            } else {
                this.updateLiveCategory();
            }
        }
    }

    handleSwitch(elem, state) {
        if (elem.props.name === 'statusSwitch') {
            this.setState({cat_status: state})
        }
    }

    cancelChannelCatEdit() {
        this.setRedirect();
    }

    setChannelCatRedirect = () => {
        this.setState({
            channelCatRedirect: true,
            back_button_text: "Back"
        })
    }

    renderSaveModal = () => {

        if (this.state.showSaveModal && this.getValidationState() === 'success') {
            return <ModalSave
                headerMessage={"Live Category: "}
                bodyMessage={"this action will save all changes, click close to cancel saving"}
                handlerFromParent={this.setSaveModal}
                handlerID={this.state.cat_id}
            />
        }

    }

    notification(status) {

        if (status === "success") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>Channel Category has been updated</b>
                    </div>
                ),
                level: 'success',
                position: 'tr',
                autoDismiss: 5,
            });
        }

        if (status === "failed") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>failed to update Category</b>
                    </div>
                ),
                level: 'error',
                position: 'tr',
                autoDismiss: 10,
            });
        }

    }

    title() {
        if (this.state.cat_name_short === null) {
            return "New - Category Settings";
        } else {
            return this.state.cat_name_short + " - Category Settings";
        }
    }


    render() {
        return (
            <div className="main-content">

                <NotificationSystem ref="notificationSystem" style={style}/>

                {this.renderRedirect()}
                {this.renderSaveModal()}


                <div className="sweet-loader">
                    <ScaleLoader
                        css={override}
                        color={'#797979'}
                        loading={this.state.loading}
                    />
                </div>

                <Grid fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6}>
                            <Card
                                title={this.title()}
                                content={
                                    <div>
                                        <legend></legend>
                                        <FormGroup>
                                            <ImageUpload
                                                imageName={this.state.cat_image}
                                                imageType="live_tv_cat"
                                                imagePosition={"cat_image"}
                                                imageTemp={this.state.cat_image_temp}
                                                imageFolderTemp={this.state.cat_image_folder_temp}
                                                handlerFromParent={this.handleChildImageSelect}
                                            />
                                        </FormGroup>
                                        <legend></legend>

                                        <FormGroup>
                                            <p className="category">{this.props.t('global.status', {framework: "react-i18next"})}</p>
                                            <Switch
                                                name={"statusSwitch"}
                                                disabled={this.state.loading}
                                                value={this.state.cat_status}
                                                onChange={(el, state) => this.handleSwitch(el, state)}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>{this.props.t('global.position', {framework: "react-i18next"})}</ControlLabel>
                                            <FormControl
                                                placeholder={this.props.t('live_category_edit.position_placeholder', {framework: "react-i18next"})}
                                                type="number"
                                                name={"cat_position"}
                                                disabled={this.state.loading}
                                                onChange={this.handleInputChange}
                                                value={this.state.cat_position}/>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>{this.props.t('live_category_edit.name_short', {framework: "react-i18next"})}</ControlLabel>
                                            <FormControl
                                                placeholder={this.props.t('live_category_edit.name_short_placeholder', {framework: "react-i18next"})}
                                                type="text"
                                                name={"cat_name_short"}
                                                disabled={this.state.loading}
                                                onChange={this.handleInputChange}
                                                value={this.state.cat_name_short}/>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>{this.props.t('live_category_edit.name_long_placeholder', {framework: "react-i18next"})}</ControlLabel>
                                            <FormControl
                                                placeholder={this.props.t('live_category_edit.name_long_placeholder', {framework: "react-i18next"})}
                                                type="text"
                                                name={"cat_name_long"}
                                                disabled={this.state.loading}
                                                onChange={this.handleInputChange}
                                                value={this.state.cat_name_long}/>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>{this.props.t('live_category_edit.description', {framework: "react-i18next"})}</ControlLabel>
                                            <FormControl componentClass="textarea"
                                                         type="text"
                                                         rows={4}
                                                         name="cat_description"
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         placeholder={this.props.t('live_category_edit.description_placeholder', {framework: "react-i18next"})}
                                                         value={this.state.cat_description}
                                            />
                                        </FormGroup>


                                    </div>
                                }
                                ftTextCenter
                                legend={
                                    <div className="buttons-with-margin">
                                        <Button bsStyle="info" fill wd
                                                name={"saveButton"}
                                                disabled={this.state.loading}
                                                onClick={() => this.setState({showSaveModal: true})}
                                        >
                                            {this.state.loading ? 'Loading...' : this.props.t('global.save', {framework: "react-i18next"})}
                                        </Button>
                                        <Button bsStyle="default" fill wd
                                                name={"cancelButton"}
                                                disabled={this.state.loading}
                                                onClick={this.cancelChannelCatEdit.bind(this)}>
                                            {this.state.back_button_text}
                                        </Button>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>

            </div>
        );
    }
}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedLiveChannelsCategoryEdit = connect(mapStateToProps)(LiveChannelsCategoryEdit);
const connectedLiveChannelsCategoryEditTrans = withNamespaces('common')(connectedLiveChannelsCategoryEdit);
export {connectedLiveChannelsCategoryEditTrans as LiveChannelsCategoryEdit};

