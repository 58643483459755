import asyncComponent from './AsyncComponent'

import Dashboard from "../views/Dashboard/Dashboard.jsx";

import {LiveChannelsEdit} from "../views/LiveChannels/LiveChannelsEdit.jsx";
import {LiveChannelsCategoryEdit} from "../views/LiveChannels/LiveChannelsCategoryEdit.jsx";
import {ListTvGuide} from "../views/TvGuide/ListTvGuide.jsx";
import {ListTvGuideDynamic} from "../views/TvGuide/ListTvGuideDynamic.jsx";
import {ListTvGuideStatic} from "../views/TvGuide/ListTvGuideStatic.jsx";
import {ListTvGuideNow} from "../views/TvGuide/ListTvGuideNow.jsx";
// import {EditTvod} from "../views/VideoOnDemand/EditTvod.jsx";
// import {EditSvod} from "../views/VideoOnDemand/EditSvod.jsx";
// import {EditAvod} from "../views/VideoOnDemand/EditAvod.jsx";
import {EditVodCategory} from "../views/VideoOnDemand/EditVodCategory.jsx";
import {EditVodSeries} from "../views/VideoOnDemand/EditVodSeries.jsx";
import {EditApplication} from "../views/Applications/EditApplication.jsx";
import {EditApplicationCategory} from "../views/Applications/EditApplicationCategory.jsx";
import {EditRadioCategory} from "../views/Radio/EditRadioCategory.jsx";
import {EditRadio} from "../views/Radio/EditRadio.jsx";
import {EditLiveEvents} from "../views/LiveEvents/EditLiveEvents.jsx";
import {EditEventCategory} from "../views/LiveEvents/EditEventCategory.jsx";
import {EditLiveMatches} from "../views/LiveSports/EditLiveMatches.jsx";
import {EditSportCategory} from "../views/LiveSports/EditSportCategory.jsx";
import EditCustomer from "../views/Customers/EditCustomer";
import {EditPackagesLive} from "../views/Packages/EditPackagesLive.jsx";
import {EditPackagesSvod} from "../views/Packages/EditPackagesSvod.jsx";
import {EditTvodPriceGroup} from "../views/PricingSetup/EditTvodPriceGroup.jsx";
import {EditPpvPriceGroup} from "../views/PricingSetup/EditPpvPriceGroup.jsx";
import {DeviceSettings} from "../views/Settings/DeviceSettings";
import {MediaManagement} from "../views/Settings/MediaManagement";
import PropertyManagement from "../views/Settings/PropertyManagement";
import {QrCodeManagement} from "../views/Settings/QrCodeManagement";
import {EditMusicAlbum} from "../views/Music/EditMusicAlbum";
import {EditKaraoke} from "../views/Karaoke/EditKaraoke";
import {EditUserAccount} from "../views/Settings/EditUserAccount";
import {EditRssFeed} from "../views/RSSFeeds/EditRssFeed";

// import ListRssFeeds from "../views/RSSFeeds/ListRssFeeds";
// import ListKaraoke from "../views/Karaoke/ListKaraoke";
// import ListMusicAlbums from "../views/Music/ListMusicAlbums";
// import ListTvodPriceGroup from "../views/PricingSetup/ListTvodPriceGroup.jsx";
// import ListPpvPriceGroup from "../views/PricingSetup/ListPpvPriceGroup.jsx";
// import ListPackagesSvod from "../views/Packages/ListPackagesSvod.jsx";
// import ListPackagesLive from "../views/Packages/ListPackagesLive.jsx";
// import ListDevices from "../views/Devices/ListDevices.jsx";
// import ListCustomers from "../views/Customers/ListCustomers.jsx";
// import ListLiveEvents from "../views/LiveEvents/ListLiveEvents.jsx";
// import ListLiveSports from "../views/LiveSports/ListLiveSports.jsx";
// import ListEventCategories from "../views/LiveEvents/ListEventCategories.jsx";
// import ListSportsCategories from "../views/LiveSports/ListSportsCategories.jsx";
// import ListRadio from "../views/Radio/ListRadio.jsx";
// import ListRadioCategories from "../views/Radio/ListRadioCategories.jsx";
// import ListApplications from "../views/Applications/ListApplications.jsx";
// import ListApplicationCategories from "../views/Applications/ListApplicationCategories.jsx";
// import ListSvod from "../views/VideoOnDemand/ListSvod.jsx";
// import ListTvod from "../views/VideoOnDemand/ListTvod.jsx";
// import ListAvod from "../views/VideoOnDemand/ListAvod.jsx";
// import ListVodSeries from "../views/VideoOnDemand/ListVodSeries.jsx";
// import ListVodCategories from "../views/VideoOnDemand/ListVodCategories.jsx";
// import ListVodHomeScreen from "../views/VideoOnDemand/ListVodHomeScreen.jsx";
// import LiveChannelCategories from "../views/LiveChannels/LiveChannelCategories.jsx";
// import LiveChannels from "../views/LiveChannels/LiveChannels.jsx";

// import Settings from "../views/Settings/Settings";
// import UserHistory from "../views/Settings/UserHistory";
// import GlobalSettings from "../views/Settings/GlobalSettings";
// import SystemHealth from "../views/Settings/SystemHealth";
// import DashBoardSettings from "../views/Settings/DashBoardSettings";
// import UserAccounts from "../views/Settings/UserAccounts";
// import WeatherSettings from "../views/Settings/WeatherSettings";
// import EASSettings from "../views/Settings/EASSettings";
// import Developers from "../views/Settings/Developers";
// import RulesLiveChannels from "../views/Settings/RulesLiveChannels";
// import WmsPanel from "../views/Wms/WmsNimble";
// import ReportsExports from "../views/Reports/ReportsExports";
// import ReportsStreaming from "../views/Reports/ReportsStreaming";

const ListRssFeeds = asyncComponent(() => import('../views/RSSFeeds/ListRssFeeds').then(module => module.default))
const ListKaraoke = asyncComponent(() => import('../views/Karaoke/ListKaraoke').then(module => module.default))
const ListMusicAlbums = asyncComponent(() => import('../views/Music/ListMusicAlbums').then(module => module.default))
const ListTvodPriceGroup = asyncComponent(() => import('../views/PricingSetup/ListTvodPriceGroup.jsx').then(module => module.default))
const ListPpvPriceGroup = asyncComponent(() => import('../views/PricingSetup/ListPpvPriceGroup.jsx').then(module => module.default))
const ListPackagesSvod = asyncComponent(() => import('../views/Packages/ListPackagesSvod.jsx').then(module => module.default))
const ListPackagesLive = asyncComponent(() => import('../views/Packages/ListPackagesLive.jsx').then(module => module.default))
const ListDevices = asyncComponent(() => import('../views/Devices/ListDevices.jsx').then(module => module.default))
const ListCustomers = asyncComponent(() => import('../views/Customers/ListCustomers.jsx').then(module => module.default))
const ListLiveEvents = asyncComponent(() => import('../views/LiveEvents/ListLiveEvents.jsx').then(module => module.default))
const ListLiveSports = asyncComponent(() => import('../views/LiveSports/ListLiveSports.jsx').then(module => module.default))
const ListEventCategories = asyncComponent(() => import('../views/LiveEvents/ListEventCategories.jsx').then(module => module.default))
const ListSportsCategories = asyncComponent(() => import('../views/LiveSports/ListSportsCategories.jsx').then(module => module.default))
const ListRadio = asyncComponent(() => import('../views/Radio/ListRadio.jsx').then(module => module.default))
const ListRadioCategories = asyncComponent(() => import('../views/Radio/ListRadioCategories.jsx').then(module => module.default))
const ListApplications = asyncComponent(() => import('../views/Applications/ListApplications.jsx').then(module => module.default))
const ListApplicationCategories = asyncComponent(() => import('../views/Applications/ListApplicationCategories.jsx').then(module => module.default))
const ListSvod = asyncComponent(() => import('../views/VideoOnDemand/ListSvod.jsx').then(module => module.default))
const ListTvod = asyncComponent(() => import('../views/VideoOnDemand/ListTvod.jsx').then(module => module.default))
const ListAvod = asyncComponent(() => import('../views/VideoOnDemand/ListAvod.jsx').then(module => module.default))
const ListVodSeries = asyncComponent(() => import('../views/VideoOnDemand/ListVodSeries.jsx').then(module => module.default))
const ListVodCategories = asyncComponent(() => import('../views/VideoOnDemand/ListVodCategories.jsx').then(module => module.default))
const ListVodHomeScreen = asyncComponent(() => import('../views/VideoOnDemand/ListVodHomeScreen.jsx').then(module => module.default))
const LiveChannelCategories = asyncComponent(() => import('../views/LiveChannels/LiveChannelCategories.jsx').then(module => module.default))
const LiveChannels = asyncComponent(() => import('../views/LiveChannels/LiveChannels.jsx').then(module => module.default))
const Settings = asyncComponent(() => import('../views/Settings/Settings').then(module => module.default))
const UserHistory = asyncComponent(() => import('../views/Settings/UserHistory').then(module => module.default))
const GlobalSettings = asyncComponent(() => import('../views/Settings/GlobalSettings').then(module => module.default))
const SystemHealth = asyncComponent(() => import('../views/Settings/SystemHealth').then(module => module.default))
const DashBoardSettings = asyncComponent(() => import('../views/Settings/DashBoardSettings').then(module => module.default))
const UserAccounts = asyncComponent(() => import('../views/Settings/UserAccounts').then(module => module.default))
const WeatherSettings = asyncComponent(() => import('../views/Settings/WeatherSettings').then(module => module.default))
const EASSettings = asyncComponent(() => import('../views/Settings/EASSettings').then(module => module.default))
const Developers = asyncComponent(() => import('../views/Settings/Developers').then(module => module.default))
const RulesLiveChannels = asyncComponent(() => import('../views/Settings/RulesLiveChannels').then(module => module.default))
const WmsPanel = asyncComponent(() => import('../views/Wms/WmsNimble').then(module => module.default))
const ReportsExports = asyncComponent(() => import('../views/Reports/ReportsExports').then(module => module.default))
const ReportsStreaming = asyncComponent(() => import('../views/Reports/ReportsStreaming').then(module => module.default))
// const SupportTicketList = asyncComponent(() => import('../views/Support/SupportTicketList').then(module => module.default))
// const SupportTicket = asyncComponent(() => import('../views/Support/SupportTicket').then(module => module.default))

const EditTvod = asyncComponent(() => import('../exports/ExportNamedEditTvod.js').then(module => module.default))
const EditSvod = asyncComponent(() => import('../exports/ExportNamedEditSvod.js').then(module => module.default))
const EditAvod = asyncComponent(() => import('../exports/ExportNamedEditAvod.js').then(module => module.default))


var dashboardRoutes = [
    //MIDDLEWARE COMPONENTS
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: "pe-7s-graph",
        component: Dashboard,
        invisible: false,
        access_level: 100
    },
    {
        collapse: true,
        path: "/live",
        name: "Live",
        state: "openLive",
        icon: "pe-7s-monitor",
        invisible: false,
        access_level: 200,
        views: [
            {
                path: "/live/channels",
                name: "TV Channels",
                editPage: "edit-channel",
                icon: "fa fa-television",
                component: LiveChannels,
                access_level: 201
            },
            {
                path: "/live/radio",
                name: "Radio Stations",
                editPage: "edit-radio",
                icon: "pe-7s-radio",
                component: ListRadio,
                access_level: 202
            },
            {
                path: "/live/events",
                name: "Events & PPV",
                editPage: "edit-event",
                icon: "fa fa-calendar",
                component: ListLiveEvents,
                access_level: 203
            },
            {
                path: "/live/sports",
                name: "Sports",
                editPage: "edit-match",
                icon: "fa fa-futbol-o",
                component: ListLiveSports,
                access_level: 204
            },
            {
                path: "/live/rss",
                name: "RSS Feeds",
                editPage: "edit-rss",
                icon: "fa fa-rss",
                component: ListRssFeeds,
                access_level: 205
            }
        ]
    },
    {
        collapse: true,
        path: "/guide",
        name: "TV Guide",
        state: "openGuide",
        icon: "pe-7s-date",
        invisible: false,
        component: ListTvGuide,
        access_level: 300,
        views: [
            {
                path: "/guide/dynamic",
                name: "Dynamic",
                mini: "GD",
                component: ListTvGuideDynamic,
                access_level: 301
            },
            {
                path: "/guide/static",
                name: "Static",
                mini: "GS",
                component: ListTvGuideStatic,
                access_level: 302
            },
            {
                path: "/guide/now",
                name: "Playing Now",
                mini: "GN",
                component: ListTvGuideNow,
                access_level: 303
            }
        ]
    },
    {
        collapse: true,
        path: "/movies",
        name: "Video On Demand",
        state: "openVod",
        icon: "pe-7s-film",
        invisible: false,
        access_level: 400,
        views: [
            {
                path: "/movies/tvod",
                name: "TVOD",
                mini: "TV",
                editPage: "edit-tvod",
                component: ListTvod,
                access_level: 401
            },
            {
                path: "/movies/svod",
                name: "SVOD",
                mini: "SV",
                editPage: "edit-svod",
                component: ListSvod,
                access_level: 402
            },
            {
                path: "/movies/avod",
                name: "AVOD",
                mini: "AV",
                editPage: "edit-avod",
                component: ListAvod,
                access_level: 403
            },
            {
                path: "/movies/vcategories",
                name: "Categories",
                mini: "VC",
                editPage: "edit-vod-cat",
                component: ListVodCategories,
                access_level: 404
            },
            {
                path: "/movies/vseries",
                name: "Series Management",
                mini: "VS",
                editPage: "edit-series",
                component: ListVodSeries,
                access_level: 405
            },
            {
                path: "/movies/home",
                name: "Home Screen",
                mini: "VH",
                component: ListVodHomeScreen,
                access_level: 406
            }
        ]
    },
    {
        path: "/applications",
        name: "Applications",
        state: "openApplications",
        icon: "pe-7s-joy",
        invisible: false,
        component: ListApplications,
        access_level: 500

    },
    {
        path: "/music",
        name: "Music Collection",
        state: "openMusic",
        icon: "pe-7s-musiclist",
        invisible: false,
        component: ListMusicAlbums,
        access_level: 600
    },
    {
        path: "/karaoke",
        name: "Karaoke",
        state: "openKaraoke",
        icon: "pe-7s-micro",
        invisible: false,
        component: ListKaraoke,
        access_level: 700
    },
    {
        collapse: true,
        path: "/categories",
        name: "Category Management",
        state: "openCategories",
        icon: "pe-7s-box1",
        invisible: false,
        access_level: 800,
        views: [
            {
                path: "/categories/channels",
                name: "TV Categories",
                icon: "fa fa-television",
                editPage: "edit-tv-cat",
                component: LiveChannelCategories,
                access_level: 801
            },
            {
                path: "/categories/radio",
                name: "Radio Categories",
                icon: "pe-7s-radio",
                editPage: "edit-radio-cat",
                component: ListRadioCategories,
                access_level: 802
            },
            {
                path: "/categories/events",
                name: "Event Categories",
                icon: "fa fa-calendar",
                editPage: "edit-event-cat",
                component: ListEventCategories,
                access_level: 803
            },
            {
                path: "/categories/sports",
                name: "Sport Categories",
                icon: "fa fa-futbol-o",
                editPage: "edit-match-cat",
                component: ListSportsCategories,
                access_level: 804
            },
            {
                path: "/categories/app-categories",
                name: "Application Categories",
                icon: "fa fa-th",
                editPage: "edit-app-cat",
                component: ListApplicationCategories,
                access_level: 805
            },
        ]
    },
    {
        path: "/customers",
        name: "Customers",
        state: "openCustomers",
        icon: "pe-7s-users",
        invisible: false,
        component: ListCustomers,
        access_level: 900
    },
    {
        path: "/devices",
        name: "Conected Devices",
        state: "openDevices",
        icon: "pe-7s-phone",
        invisible: false,
        component: ListDevices,
        access_level: 1000
    },
    // {
    //     path: "/support",
    //     name: "Tickets (Under Dev)",
    //     state: "openSupport",
    //     icon: "fa fa-support",
    //     invisible: false,
    //     component: SupportTicketList,
    //     access_level: 1000
    // },
    // {
    //     path: "/messaging",
    //     name: "Notifications & Messages",
    //     state: "openNotifications",
    //     icon: "pe-7s-paper-plane",
    //     invisible: false,
    //     component: ListNotificationsAndMessages,
    //     access_level: 2000
    // },
    {
        collapse: true,
        path: "/packages",
        name: "Package Setup",
        state: "openPackages",
        icon: "pe-7s-gift",
        invisible: false,
        access_level: 3000,
        views: [
            {
                path: "/packages/tv-package",
                name: "TV Packages",
                mini: "LP",
                editPage: "edit-live-package",
                component: ListPackagesLive
            },
            {
                path: "/packages/svod-package",
                name: "SVOD Packages",
                mini: "SP",
                editPage: "edit-svod-package",
                component: ListPackagesSvod
            }
        ]
    },
    {
        collapse: true,
        path: "/pricing",
        name: "Pricing Setup",
        state: "openPricing",
        icon: "pe-7s-piggy",
        invisible: false,
        access_level: 4000,
        views: [
            {
                path: "/pricing/tvod-price",
                name: "TVOD pricing",
                mini: "TP",
                editPage: "edit-tvod-price",
                component: ListTvodPriceGroup
            },
            {
                path: "/pricing/ppv-price",
                name: "PPV pricing",
                mini: "PP",
                editPage: "edit-ppv-price",
                component: ListPpvPriceGroup
            }
        ]
    },
    {
        collapse: true,
        path: "/reports",
        name: "Reports",
        state: "openReports",
        icon: "pe-7s-graph1",
        invisible: false,
        access_level: 5000,
        views: [
            {
                path: "/reports/reports-exports",
                name: "Exports",
                mini: "RE",
                component: ReportsExports
            },
            {
                path: "/reports/reports-streaming",
                name: "Streaming",
                mini: "RS",
                component: ReportsStreaming
            },
        ]
    },

    {
        path: "/settings",
        name: "Settings",
        state: "openSettings",
        icon: "pe-7s-config",
        component: Settings,
        invisible: false,
        access_level: 6000,
    },


    {
        collapse: false,
        path: "/settings-user",
        name: "User Accounts",
        component: UserAccounts,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/settings-device",
        name: "Device Settings",
        component: DeviceSettings,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/settings-weather",
        name: "Weather Configuration",
        component: WeatherSettings,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/settings-eas",
        name: "EAS Configuration",
        component: EASSettings,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/settings-history",
        name: "User History",
        component: UserHistory,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/settings-global",
        name: "Global Settings",
        component: GlobalSettings,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/settings-health",
        name: "System Health",
        component: SystemHealth,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/settings-dashboard",
        name: "Dashboard Settings",
        component: DashBoardSettings,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/settings-developers",
        name: "Developer Settings",
        component: Developers,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/settings-rules-lc",
        name: "Rules: Live Channels",
        component: RulesLiveChannels,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/settings-wms",
        name: "WMS Panel",
        component: WmsPanel,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/settings-media",
        name: "Media Management",
        component: MediaManagement,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/settings-properties",
        name: "Property Management",
        component: PropertyManagement,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/settings-qr",
        name: "QR Code - Management",
        component: QrCodeManagement,
        invisible: true,
        edit: true
    },
    // EDITS
    {
        collapse: false,
        path: "/edit-channel/:handle",
        name: "Edit Channel",
        component: LiveChannelsEdit,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/edit-rss/:handle",
        name: "Edit Rss Feed",
        component: EditRssFeed,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/add-channel/:handle",
        name: "Add New Channel",
        component: LiveChannelsEdit,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/edit-radio/:handle",
        name: "Edit Radio Station",
        component: EditRadio,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/edit-app/:handle",
        name: "Edit App",
        component: EditApplication,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/edit-live-package/:handle",
        name: "Edit Live Package",
        component: EditPackagesLive,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/edit-svod-package/:handle",
        name: "Edit Svod Package",
        component: EditPackagesSvod,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/edit-tvod/:handle",
        name: "Edit TVOD",
        component: EditTvod,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/edit-avod/:handle",
        name: "Edit AVOD",
        component: EditAvod,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/edit-svod/:handle",
        name: "Edit SVOD",
        component: EditSvod,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/add-tvod/:handle",
        name: "Add New TVOD",
        component: EditTvod,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/add-avod/:handle",
        name: "Add New AVOD",
        component: EditAvod,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/add-svod/:handle",
        name: "Add New SVOD",
        component: EditSvod,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/edit-customer/:handle",
        name: "Edit Customer",
        component: EditCustomer,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/add-customer/:handle",
        name: "Add Customer",
        component: EditCustomer,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/add-child/:parent/:handle",
        name: "Add Child Account",
        component: EditCustomer,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/add-bedroom/:common_area/:handle",
        name: "Add Bedroom Account",
        component: EditCustomer,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/edit-customer-child/:handle",
        name: "Edit Child Customer",
        component: EditCustomer,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/edit-customer-bedroom/:handle",
        name: "Edit Child Customer",
        component: EditCustomer,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/edit-customer-parent/:handle",
        name: "Edit Parent Customer",
        component: EditCustomer,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/edit-event/:handle",
        name: "Edit Live Event",
        component: EditLiveEvents,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/edit-match/:handle",
        name: "Edit Live Match",
        component: EditLiveMatches,
        invisible: true,
        edit: true
    },

    //ADD-EDIT LIVE TV CAT
    {
        collapse: false,
        path: "/categories/add-tv-cat/:handle",
        name: "Add TV Category",
        component: LiveChannelsCategoryEdit,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/categories/edit-tv-cat/:handle",
        name: "Edit TV Category",
        component: LiveChannelsCategoryEdit,
        invisible: true,
        edit: true
    },

    //ADD-EDIT LIVE EVENT CAT
    {
        collapse: false,
        path: "/categories/add-event-cat/:handle",
        name: "Add Event Category",
        component: EditEventCategory,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/categories/edit-event-cat/:handle",
        name: "Edit Event Category",
        component: EditEventCategory,
        invisible: true,
        edit: true
    },

    //ADD-EDIT LIVE SPORT CAT
    {
        collapse: false,
        path: "/categories/add-match-cat/:handle",
        name: "Edit Match Category",
        component: EditSportCategory,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/categories/edit-match-cat/:handle",
        name: "Edit Match Category",
        component: EditSportCategory,
        invisible: true,
        edit: true
    },


    //ADD-EDIT RADIO CAT
    {
        collapse: false,
        path: "/categories/edit-radio-cat/:handle",
        name: "Edit Radio Category",
        component: EditRadioCategory,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/categories/add-radio-cat/:handle",
        name: "Add Radio Category",
        component: EditRadioCategory,
        invisible: true,
        edit: true
    },

    //ADD-EDIT APPLICATION CAT
    {
        collapse: false,
        path: "/categories/add-app-cat/:handle",
        name: "Add App category",
        component: EditApplicationCategory,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/categories/edit-app-cat/:handle",
        name: "Edit App category",
        component: EditApplicationCategory,
        invisible: true,
        edit: true
    },

    //ADD-EDIT VOD CAT
    {
        collapse: false,
        path: "/add-vod-cat/:handle",
        name: "Add Video Category",
        component: EditVodCategory,
        invisible: true
    },
    {
        collapse: false,
        path: "/edit-vod-cat/:handle",
        name: "Edit Video Category",
        component: EditVodCategory,
        invisible: true
    },

    {
        collapse: false,
        path: "/add-series/:handle",
        name: "Add Series",
        component: EditVodSeries,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/edit-series/:handle",
        name: "Edit Series",
        component: EditVodSeries,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/edit-tvod-price/:handle",
        name: "Edit TVOD Price Group",
        component: EditTvodPriceGroup,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/edit-ppv-price/:handle",
        name: "Edit PPV Price Group",
        component: EditPpvPriceGroup,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/edit-karaoke/:handle",
        name: "Edit Karaoke title",
        component: EditKaraoke,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/edit-music-album/:handle",
        name: "Edit Music Album",
        component: EditMusicAlbum,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/edit-user/:handle",
        name: "Edit User",
        component: EditUserAccount,
        invisible: true,
        edit: true
    },
    {
        collapse: false,
        path: "/add-user/:handle",
        name: "Add User",
        component: EditUserAccount,
        invisible: true,
        edit: true
    },
    // END OF EDITS
    {redirect: true, path: "/", pathTo: "/dashboard", name: "Dashboard"}
];

export default dashboardRoutes;

