const loadImage = {

    live_tv: function (value) {

        if (value === null || value === undefined || value === '' || value === 'comp_') {
            let image = process.env.REACT_APP_ASSET_URL + "/global/noimage.png"
            return image;
        } else {
            let image = process.env.REACT_APP_ASSET_URL + "/live_tv/" + value
            return image;
        }

    },

    live_tv_cat: function (value) {

        if (value === null || value === undefined || value === '' || value === 'comp_') {
            let image = process.env.REACT_APP_ASSET_URL + "/global/noimage.png"
            return image;
        } else {
            let image = process.env.REACT_APP_ASSET_URL + "/live_tv_cat/" + value
            return image;
        }

    },
    load_application: function (value) {

        if (value === null || value === undefined || value === '' || value === 'comp_') {
            let image = process.env.REACT_APP_ASSET_URL + "/global/noimage.png"
            return image;
        } else {
            let image = process.env.REACT_APP_ASSET_URL + "/apps_list/" + value
            return image;
        }
    },
    load_application_cat: function (value) {

        if (value === null || value === undefined || value === '' || value === 'comp_') {
            let image = process.env.REACT_APP_ASSET_URL + "/global/noimage.png"
            return image;
        } else {
            let image = process.env.REACT_APP_ASSET_URL + "/apps_cat/" + value
            return image;
        }

    },
    load_music_album: function (value) {

        if (value === null || value === undefined || value === '' || value === 'comp_') {
            let image = process.env.REACT_APP_ASSET_URL + "/global/noimage.png"
            return image;
        } else {
            let image = process.env.REACT_APP_ASSET_URL + "/music_list/" + value
            return image;
        }

    },
    load_radio: function (value) {
        if (value === null || value === undefined || value === '' || value === 'comp_') {
            let image = process.env.REACT_APP_ASSET_URL + "/global/noimage.png"
            return image;
        } else {
            let image = process.env.REACT_APP_ASSET_URL + "/live_radio/" + value
            return image;
        }
    },
    load_live_events: function (value) {
        if (value === null || value === undefined || value === '' || value === 'comp_') {
            let image = process.env.REACT_APP_ASSET_URL + "/global/poster_image_empty.png"
            return image;
        } else {
            let image = process.env.REACT_APP_ASSET_URL + "/live_events/" + value
            return image;
        }
    },
    load_tvod: function (value) {
        if (value === null || value === undefined || value === '' || value === 'comp_') {
            let image = process.env.REACT_APP_ASSET_URL + "/global/poster_image_empty.png"
            return image;
        } else {
            let image = process.env.REACT_APP_ASSET_URL + "/vod_tvod/" + value
            return image;
        }
    },
    load_svod: function (value) {
        if (value === null || value === undefined || value === '' || value === 'comp_') {
            let image = process.env.REACT_APP_ASSET_URL + "/global/poster_image_empty.png"
            return image;
        } else {
            let image = process.env.REACT_APP_ASSET_URL + "/vod_svod/" + value
            return image;
        }
    },
    load_avod: function (value) {
        if (value === null || value === undefined || value === '' || value === 'comp_') {
            let image = process.env.REACT_APP_ASSET_URL + "/global/poster_image_empty.png"
            return image;
        } else {
            let image = process.env.REACT_APP_ASSET_URL + "/vod_avod/" + value
            return image;
        }
    },
    load_series: function (value) {

        if (value === null || value === undefined || value === '' || value === 'comp_') {
            let image = process.env.REACT_APP_ASSET_URL + "/global/poster_image_empty.png"
            return image;
        } else {
            let image = process.env.REACT_APP_ASSET_URL + "/vod_series/" + value
            return image;
        }
    },
    load_property_image: function (value) {

        // console.log(value)
        if (value === null || value === undefined || value === '' || value === 'comp_') {
            let image = process.env.REACT_APP_ASSET_URL + "/global/noimage.png"
            return image;
        } else {
            let image = process.env.REACT_APP_ASSET_URL + "/properties/" + value
            return image;
        }
    }

}


export {
    loadImage
};