import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction' // needed for dayClick
import './main.scss'
import {userActions} from "../../authentication/_actions";
import axios from "axios";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import ModalViewCalendarEvent from "../CustomModals/ModalViewCalendarEvent";

class Calendar extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            calendarWeekends: true,
            showCalendarEventModal: false,
            eventTitle: null,
            eventStart: null,
            eventEnd: null,

            calendarEvents: [ // initial event data
                {title: 'No Event found - Now', start: new Date()}
            ]
        }

    }

    getData() {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        let url = "";

        console.log(this.props.eventType)

        if (this.props.eventType === "local") {
            url = process.env.REACT_APP_API_URL + "/LiveEvent/Schedule/Local";
        } else {
            url = process.env.REACT_APP_API_URL + "/LiveEvent/Schedule/" + this.props.showId + "/" + this.props.seriesId;
        }

        axios
            .get(url, axiosConfig)
            .then(res => {
                this.setState({calendarEvents: res.data});
            })
            .catch(error => {
                console.log(error);
            });


    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.seriesId !== prevProps.seriesId) {
            this.getData();
        }
        if (this.props.eventType !== prevProps.eventType) {
            this.getData();
        }
    }

    componentDidMount() {
        console.log(this.props.eventType)
        if(this.props.eventType !==  null ){
            if(this.props.eventType === "local"){
                this.getData();
            }
        }
    }

    renderCalendarEventModal = () => {

        if (this.state.showCalendarEventModal) {
            return <ModalViewCalendarEvent
                headerMessage={this.state.eventTitle}
                eventStart={this.state.eventStart}
                eventEnd={this.state.eventEnd}
                handlerFromParent={this.setCloseCalendarEventModal}
            />
        }
    }

    setCloseCalendarEventModal = (value, action) => {

        this.setState({showCalendarEventModal: value, eventTitle: null, eventStart: null, eventEnd: null})

    }

    render() {
        return (
            <div className='inner-event'>

                {this.renderCalendarEventModal()}

                <div className='event-calendar'>
                    <FullCalendar
                        defaultView="dayGridMonth"
                        header={{
                            left: 'prev,next today',
                            center: 'title',
                            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                        }}
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        weekends={this.state.calendarWeekends}
                        events={this.state.calendarEvents}
                        eventClick={this.handleDateClick}
                        nowIndicator={true}
                    />
                </div>
            </div>
        )
    }

    handleDateClick = (arg) => {

        let eventTitle = arg.event.title
        let eventStart = arg.event.start.toString()
        let eventEnd = arg.event.start.toString()

        this.setState({showCalendarEventModal: true, eventTitle, eventStart, eventEnd})
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedCalendar = connect(mapStateToProps)(Calendar);
const connectedCalendarTrans = withNamespaces('common')(connectedCalendar);
export {connectedCalendarTrans as Calendar};