import React, {Component} from "react";
import {Grid, Row, Col, FormGroup, ControlLabel, FormControl, InputGroup, Glyphicon} from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import axios from "axios/index";
import { css } from "@emotion/core";
import {ScaleLoader} from 'react-spinners';
import {Redirect} from "react-router";
import Button from "../../components/CustomButton/CustomButton";
import ModalSave from "../../components/CustomModals/ModalSave";
import {DualListBoxPackageLive} from "../../components/DualListBox/DualListBoxPackageLive";
import 'react-dual-listbox/lib/react-dual-listbox.css';
import {userActions} from "../../authentication/_actions";
import Switch from "react-bootstrap-switch";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import NotificationSystem from "react-notification-system";
import {style} from "../../variables/Variables";

const override = css`
    position: fixed;
    left: 50%;
    top: 35%;
    display: block;
    z-index: 1000;
    height: 31px;
    width: 100px;
    `;

class EditPackagesLive extends Component {

    constructor(props) {
        super(props);
        this.state = {

            loading: false,

            redirect: false,
            livePackageRedirect: false,

            back_button_text: "Cancel",
            showSaveModal: false,

            livePackageObject: [],

            livepackage_id: 0,
            livepackage_name: null,
            livepackage_name_long: null,
            livepackage_description: null,
            livepackage_status: false,
            livepackage_price: 0.0,
            livepackage_image: null,
            livepackage_inactive_image: null,
            livepackage_is_adult: false,
            livepackage_no_expiry: false,
            livepackage_channels_assign: null,
            livepackage_display_while_inactive: false,
            livepackage_billing_code: null,
            livepackage_time_limit: null,
            livepackage_duration: null,
            livepackage_device_limit: null,
            livepackage_subscription_url:null,
            livepackage_trial:null,

            livepackage_image_temp: false,
            livepackage_image_folder_temp: "",

            assignedChannelsInPackage: [],

            statusSwitch: false,
            adultSwitch: false,
            expirySwitch: false,
            trialSwitch: false,

            _notificationSystem: null
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleLivePackageChannelChange = this.handleLivePackageChannelChange.bind(this);

    }

    handleLivePackageChannelChange(selectedFromChild) {

        let selectedChannels = []

        if (selectedFromChild !== undefined !== null) {

            for (let i = 0; i < selectedFromChild.length; i++) {
                //console.log(selectedFromChild[i].toString())
                selectedChannels.push(selectedFromChild[i].toString())
            }
        }

        this.setState({assignedChannelsInPackage: selectedChannels})
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSwitch(elem, state) {
        if (elem.props.name === 'statusSwitch') {
            this.setState({statusSwitch: state})
        }
        if (elem.props.name === 'expirySwitch') {
            this.setState({expirySwitch: state})
        }
        if (elem.props.name === 'adultSwitch') {
            this.setState({adultSwitch: state})
        }
        if (elem.props.name === 'trialSwitch') {
            this.setState({trialSwitch: state})
        }
    }

    componentDidMount() {

        this.setState({_notificationSystem: this.refs.notificationSystem})

        const {handle} = this.props.match.params

        if (handle === 'new') {
            this.setState({loading: false})

        } else {

            this.setState({loading: true})
            const url = process.env.REACT_APP_API_URL + "/LivePackage/" + handle;

            let user = JSON.parse(localStorage.getItem('user'));
            let token = ""

            if (user) {
                token = user.token
            } else {
                this.props.dispatch(userActions.logoutUnauthorized());
            }

            let axiosConfig = {
                headers: {'X-Id-Token': token}
            };

            axios
                .get(url, axiosConfig)
                .then(res => {

                    const livePackageObject = res.data[0];
                    const loading = false;

                    //console.log(livePackageObject)

                    let selectedChannels = []

                    if (livePackageObject.livepackage_channels_assign !== null) {
                        selectedChannels = livePackageObject.livepackage_channels_assign.toString().split(",")
                    } else {
                        selectedChannels = []
                    }

                    let status = livePackageObject.livepackage_status === 1
                    let adultSwitch = livePackageObject.livepackage_is_adult === 1
                    let expirySwitch = livePackageObject.livepackage_no_expiry === 1
                    let trialSwitch = livePackageObject.livepackage_trial === 1

                    //console.log(selectedChannels)

                    this.setState({
                        back_button_text: "Back",
                        livepackage_id: livePackageObject.livepackage_id,
                        livepackage_name: livePackageObject.livepackage_name,
                        livepackage_name_long: livePackageObject.livepackage_name_long,
                        livepackage_description: livePackageObject.livepackage_description,
                        livepackage_status: livePackageObject.livepackage_status,
                        livepackage_price: livePackageObject.livepackage_price,
                        livepackage_duration: livePackageObject.livepackage_duration,
                        livepackage_image: livePackageObject.livepackage_image,
                        livepackage_inactive_image: livePackageObject.livepackage_inactive_image,
                        livepackage_is_adult: livePackageObject.livepackage_is_adult,
                        livepackage_channels_assign: selectedChannels,
                        livepackage_display_while_inactive: livePackageObject.livepackage_display_while_inactive,
                        livepackage_billing_code: livePackageObject.livepackage_billing_code,
                        livepackage_subscription_url: livePackageObject.livepackage_subscription_url,
                        livepackage_trial: livePackageObject.livepackage_trial,
                        assignedChannelsInPackage: selectedChannels,
                        statusSwitch: status,
                        adultSwitch,
                        expirySwitch,
                        trialSwitch,
                        loading,
                        livePackageObject
                    });
                })
                .catch(error => {
                    this.setState({loading: false});
                    console.log(error);
                    //this.props.dispatch(userActions.logoutUnauthorized());
                });
        }
    }

    updateLivePackage() {

        this.setState({loading: true})

        const {handle} = this.props.match.params
        const url = process.env.REACT_APP_API_URL + "/LivePackage/" + handle;

        let channelsInPackage = []

        for (let i = 0; i < this.state.assignedChannelsInPackage.length; i++) {
            channelsInPackage.push(this.state.assignedChannelsInPackage[i])
        }

        let status = this.state.statusSwitch === false ? 0 : 1
        let adultSwitch = this.state.adultSwitch === false ? 0 : 1
        let expirySwitch = this.state.expirySwitch === false ? 0 : 1
        let trialSwitch = this.state.trialSwitch === false ? 0 : 1

        // Send a PUT request
        var postData = {
            live_package: {
                livepackage_id: this.state.livepackage_id,
                livepackage_name: this.state.livepackage_name,
                livepackage_name_long: this.state.livepackage_name_long,
                livepackage_description: this.state.livepackage_description,
                livepackage_status: status,
                livepackage_price: this.state.livepackage_price,
                livepackage_duration: this.state.livepackage_duration,
                livepackage_image: this.state.livepackage_image,
                livepackage_inactive_image: null,
                livepackage_is_adult: adultSwitch,
                livepackage_no_expiry: expirySwitch,
                livepackage_channels_assign: channelsInPackage.join(),
                livepackage_display_while_inactive: null,
                livepackage_all_channel_image: null,
                livepackage_billing_code: this.state.livepackage_billing_code,
                livepackage_time_limit: this.state.livepackage_time_limit,
                livepackage_device_limit: this.state.livepackage_device_limit,
                livepackage_subscription_url: this.state.livepackage_subscription_url,
                livepackage_trial: trialSwitch
            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            }
        };

        axios.put(url, postData, axiosConfig)
            .then((res) => {

                if (res.data.error === 301) {
                    this.setState({
                        loading: false
                    })
                    this.notification("failed")
                } else if (res.data.error === 302) {
                    this.setState({
                        loading: false
                    })
                    this.notification("failed")
                } else {
                    this.setState({loading: false})
                    this.notification("success")
                }

            })
            .catch((err) => {
                console.log("ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());
            })

    }

    createLivePackage() {

        this.setState({loading: true})

        const url = process.env.REACT_APP_API_URL + "/LivePackage";

        let channelsInPackage = []

        for (let i = 0; i < this.state.assignedChannelsInPackage.length; i++) {
            channelsInPackage.push(this.state.assignedChannelsInPackage[i])
        }

        let status = this.state.statusSwitch === false ? 0 : 1
        let adultSwitch = this.state.adultSwitch === false ? 0 : 1
        let expirySwitch = this.state.expirySwitch === false ? 0 : 1
        let trialSwitch = this.state.trialSwitch === false ? 0 : 1

        var postData = {
            live_package: {
                livepackage_name: this.state.livepackage_name,
                livepackage_name_long: this.state.livepackage_name_long,
                livepackage_description: this.state.livepackage_description,
                livepackage_status: status,
                livepackage_price: this.state.livepackage_price,
                livepackage_duration: this.state.livepackage_duration,
                livepackage_image: this.state.livepackage_image,
                livepackage_inactive_image: null,
                livepackage_is_adult: adultSwitch,
                livepackage_no_expiry: expirySwitch,
                livepackage_channels_assign: channelsInPackage.join(),
                livepackage_display_while_inactive: null,
                livepackage_billing_code: this.state.livepackage_billing_code,
                livepackage_time_limit: this.state.livepackage_time_limit,
                livepackage_device_limit: this.state.livepackage_device_limit,
                livepackage_subscription_url: this.state.livepackage_subscription_url,
                livepackage_trial: trialSwitch
            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            }
        };

        axios.post(url, postData, axiosConfig)
            .then((res) => {
                this.setState({loading: false})

                if (res.data.error === 301) {
                    this.setState({
                        loading: false
                    })
                    this.notification("failed")
                } else if (res.data.error === 302) {
                    this.setState({
                        loading: false
                    })
                    this.notification("failed")
                }else{
                    const livePackageObject = res.data;
                    this.setState({livepackage_id: livePackageObject.livepackage_id,  loading: false})
                    this.notification("success")
                    this.setLivePackageRedirect();
                }

            })
            .catch((err) => {
                console.log("ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());
            })

    }

    setSaveModal = (value, action) => {

        this.setState({
            showSaveModal: value

        })

        if (action === "save") {
            this.setState({loading: true})

            const {handle} = this.props.match.params

            if (handle === 'new') {
                this.createLivePackage();
            } else {
                this.updateLivePackage();
            }
        }
    }

    cancelLivePackageEdit() {
        this.setRedirect();
    }

    setLivePackageRedirect = () => {
        this.setState({
            livePackageRedirect: true,
            back_button_text: "Back"
        })
    }

    renderSaveModal = () => {

        if (this.state.showSaveModal) {
            return <ModalSave
                headerMessage={"Live Package: "}
                bodyMessage={"this action will save all changes, click close to cancel saving"}
                handlerFromParent={this.setSaveModal}
                handlerID={this.state.livepackage_id === 0 ? '' : this.state.livepackage_id}
            />
        }

    }

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    renderRedirect = () => {

        let path = "/packages/tv-package";
        let pathLivePackage = "/edit-live-package/" + this.state.livepackage_id;

        if (this.state.redirect) {
            return <Redirect push to={path}/>
        }

        if (this.state.livePackageRedirect) {
            return <Redirect push to={pathLivePackage}/>
        }

    }

    renderSelection = () => {

        return (
            <Card

                content={
                    <div>
                        <h5>Channels Selection</h5>
                        <legend/>
                        <Grid fluid>
                            <Row>
                                <DualListBoxPackageLive
                                    channelsInPackage={this.state.assignedChannelsInPackage}
                                    packageID={this.state.livepackage_id}
                                    handlerFromParent={this.handleLivePackageChannelChange}
                                />
                            </Row>
                        </Grid>
                    </div>
                }
            />
        );

    }

    renderDurationFields = () => {

        if(!this.state.expirySwitch){
            return (
                <FormGroup>
                    <ControlLabel>Duration (Days)</ControlLabel>
                    <FormControl placeholder="Enter the duration"
                                 type="number"
                                 name={"livepackage_duration"}
                                 disabled={this.state.loading}
                                 onChange={this.handleInputChange}
                                 value={this.state.livepackage_duration || ""}/>
                </FormGroup>
            );
        }


    }

    notification(status) {

        if (status === "success") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>Live package has been updated</b>
                    </div>
                ),
                level: 'success',
                position: 'tr',
                autoDismiss: 5,
            });
        }

        if (status === "failed") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>failed to update Live package</b>
                    </div>
                ),
                level: 'error',
                position: 'tr',
                autoDismiss: 10,
            });
        }

    }

    render() {
        return (
            <div className="main-content">

                <NotificationSystem ref="notificationSystem" style={style}/>

                {this.renderRedirect()}
                {this.renderSaveModal()}


                <div className="sweet-loader">
                    <ScaleLoader
                        css={override}
                        color={'#797979'}
                        loading={this.state.loading}
                    />
                </div>


                <Grid fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6}>
                            <Card
                                content={
                                    <div>
                                        <h5>Live Package Information</h5>
                                        <legend/>
                                        <FormGroup>
                                            <Row>
                                                <Col md={3}>
                                                    <ControlLabel>Status</ControlLabel>
                                                    <br/>
                                                    <Switch
                                                        name={"statusSwitch"}
                                                        disabled={this.state.loading}
                                                        value={this.state.statusSwitch}
                                                        onChange={(el, state) => this.handleSwitch(el, state)}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <ControlLabel>No Expiry</ControlLabel>
                                                    <br/>
                                                    <Switch
                                                        name={"expirySwitch"}
                                                        disabled={this.state.loading}
                                                        value={this.state.expirySwitch}
                                                        onChange={(el, state) => this.handleSwitch(el, state)}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <ControlLabel>Trial</ControlLabel>
                                                    <br/>
                                                    <Switch
                                                        name={"trialSwitch"}
                                                        disabled={this.state.loading || this.state.expirySwitch}
                                                        value={this.state.trialSwitch}
                                                        onChange={(el, state) => this.handleSwitch(el, state)}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <ControlLabel>Adult</ControlLabel>
                                                    <br/>
                                                    <Switch
                                                        name={"adultSwitch"}
                                                        disabled={this.state.loading}
                                                        value={this.state.adultSwitch}
                                                        onChange={(el, state) => this.handleSwitch(el, state)}
                                                    />
                                                </Col>
                                            </Row>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>Short Name</ControlLabel>
                                            <FormControl placeholder="Enter Short Name"
                                                         type="text"
                                                         name={"livepackage_name"}
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         value={this.state.livepackage_name || ""}/>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>Long Name</ControlLabel>
                                            <FormControl placeholder="Enter Long Name"
                                                         type="text"
                                                         name={"livepackage_name_long"}
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         value={this.state.livepackage_name_long || ""}/>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>Description</ControlLabel>
                                            <FormControl componentClass="textarea"
                                                         type="text"
                                                         rows={4}
                                                         name="livepackage_description"
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         placeholder="Enter the description"
                                                         value={this.state.livepackage_description || ""}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>Reference ID</ControlLabel>
                                            <FormControl placeholder="Enter the price"
                                                         type="text"
                                                         name={"livepackage_billing_code"}
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         value={this.state.livepackage_billing_code || ""}/>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>Price</ControlLabel>
                                            <FormControl placeholder="Enter the price"
                                                         type="number"
                                                         name={"livepackage_price"}
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         value={this.state.livepackage_price || ""}/>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>External URL</ControlLabel>
                                            <InputGroup>
                                                <InputGroup.Addon style={{backgroundColor:"lightgray"}}>
                                                    <Glyphicon glyph="globe"/>
                                                </InputGroup.Addon>
                                                <FormControl placeholder={this.props.t('packages_svod_edit.vodpackage_subscription_url_placeholder', {framework: "react-i18next"})}
                                                             type="text"
                                                             name="livepackage_subscription_url"
                                                             disabled={this.state.loading}
                                                             onChange={this.handleInputChange}
                                                             value={this.state.livepackage_subscription_url || ""}
                                                />
                                            </InputGroup>
                                        </FormGroup>


                                        {this.renderDurationFields()}

                                    </div>
                                }
                                ftTextCenter
                                legend={
                                    <div className="buttons-with-margin">
                                        <Button bsStyle="info" fill wd
                                                name={"saveButton"}
                                                disabled={this.state.loading}
                                                onClick={() => this.setState({showSaveModal: true})}
                                        >
                                            Save
                                        </Button>
                                        <Button bsStyle="default" fill wd
                                                name={"cancelButton"}
                                                disabled={this.state.loading}
                                                onClick={this.cancelLivePackageEdit.bind(this)}>
                                            {this.state.back_button_text}
                                        </Button>
                                    </div>
                                }
                            />
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={6}>
                            <Card
                                content={
                                    <div>
                                        <h5>Assets</h5>
                                        <legend/>
                                        <Grid fluid>
                                            <Row>
                                                <Col md={6}>
                                                    <div className="text-center">
                                                        <p>Active</p>
                                                    </div>
                                                    <ImageUpload
                                                        imageName={this.state.cat_image}
                                                        imageType="live_tv_cat"
                                                        imagePosition={"cat_image"}
                                                        imageTemp={this.state.cat_image_temp}
                                                        imageFolderTemp={this.state.cat_image_folder_temp}
                                                        handlerFromParent={this.handleChildImageSelect}
                                                    />
                                                </Col>
                                                <Col md={6}>
                                                    <div className="text-center">
                                                        <p>Inactive</p>
                                                    </div>
                                                    <ImageUpload
                                                        imageName={this.state.cat_image}
                                                        imageType="live_tv_cat"
                                                        imagePosition={"cat_image"}
                                                        imageTemp={this.state.cat_image_temp}
                                                        imageFolderTemp={this.state.cat_image_folder_temp}
                                                        handlerFromParent={this.handleChildImageSelect}
                                                    />
                                                </Col>
                                            </Row>
                                        </Grid>
                                    </div>
                                }
                            />
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={6}>
                            {this.renderSelection()}
                        </Col>

                    </Row>
                </Grid>

            </div>

        );
    }
}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedEditPackagesLive = connect(mapStateToProps)(EditPackagesLive);
const connectedEditPackagesLiveTrans = withNamespaces('common')(connectedEditPackagesLive);
export {connectedEditPackagesLiveTrans as EditPackagesLive};
