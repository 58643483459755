import React from "react";
import ReactTable from "react-table";

class ReactTableV6Simple extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: false,
            columns: []
        }

    }

    componentDidMount() {

        this.setState({
                columns: this.props.columns,
                data: this.props.data,
                loading: this.props.loading
            }
        );

    }

    render() {
        return (
            <ReactTable
                data={this.props.data}
                filterable
                loading={this.props.loading}
                columns={this.props.columns}
                defaultPageSize={this.props.defaultPageSize}
                showPaginationBottom
                className="-striped -highlight"
            />
        );

    }

}

export default ReactTableV6Simple;
