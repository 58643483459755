import React, {Component} from "react";
import {Grid, Row, Col, FormGroup, FormControl, InputGroup, ControlLabel, Glyphicon} from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import axios from "axios/index";
import { css } from "@emotion/core";
import {ScaleLoader} from 'react-spinners';
import {Redirect} from "react-router";
import Button from "../../components/CustomButton/CustomButton";
import ModalSave from "../../components/CustomModals/ModalSave";
import 'react-dual-listbox/lib/react-dual-listbox.css';
import {userActions} from "../../authentication/_actions";
import Switch from "react-bootstrap-switch";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import {DualListBoxPackageSvodSeries} from "../../components/DualListBox/DualListBoxPackageSvodSeries";
import {DualListBoxPackageSvodMovies} from "../../components/DualListBox/DualListBoxPackageSvodMovies";
import Select from "react-select";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import NotificationSystem from "react-notification-system";
import {style} from "../../variables/Variables";

const override = css`
    position: fixed;
    left: 50%;
    top: 35%;
    display: block;
    z-index: 1000;
    height: 31px;
    width: 100px;
    `;

const packageType = [
    {value: 'ALL', label: 'Both Movies & Series'},
    {value: 'MOVIES', label: 'Only Movies'},
    {value: 'SERIES', label: 'Only Series'}
];

class EditPackagesSvod extends Component {

    constructor(props) {
        super(props);
        this.state = {

            loading: false,

            redirect: false,
            vodPackageRedirect: false,

            back_button_text: "Cancel",
            showSaveModal: false,

            svodPackageObject: [],

            vodpackage_id: 0,
            vodpackage_name_short: null,
            vodpackage_name_long: null,
            vodpackage_description: null,
            vodpackage_status: false,
            vodpackage_price: 0.0,
            vodpackage_image: null,
            vodpackage_inactive_image: null,
            vodpackage_is_adult: false,
            vodpackage_display_while_inactive: false,
            vodpackage_billing_code: null,
            vodpackage_subscription_url: null,
            vodpackage_trial: null,

            vodpackage_image_temp: false,
            vodpackage_image_folder_temp: "",

            statusSwitch: false,
            expirySwitch: false,
            adultSwitch: false,
            trialSwitch: false,

            assignedSvodInPackage: [],
            vodpackage_type: null,

            vodpackage_duration:null,

            newPackage: true,

            _notificationSystem: null

        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSvodPackageChannelChange = this.handleSvodPackageChannelChange.bind(this);

    }

    handleSvodPackageTypeChange = (packageTypeDropDown) => {

        this.setState({
            packageTypeDropDown,
            vodpackage_type: packageTypeDropDown === null ? null : packageTypeDropDown.value
        });
        console.log(`Option selected:`, packageTypeDropDown);

    }

    handleSvodPackageChannelChange(selectedFromChild) {

        let selectedSvod = []

        if (selectedFromChild !== undefined !== null) {

            for (let i = 0; i < selectedFromChild.length; i++) {
                //console.log(selectedFromChild[i].toString())
                selectedSvod.push(selectedFromChild[i].toString())
            }
        }

        this.setState({assignedSvodInPackage: selectedSvod})
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSwitch(elem, state) {
        if (elem.props.name === 'statusSwitch') {
            this.setState({statusSwitch: state})
        }
        if (elem.props.name === 'expirySwitch') {
            this.setState({expirySwitch: state})
        }
        if (elem.props.name === 'adultSwitch') {
            this.setState({adultSwitch: state})
        }
        if (elem.props.name === 'trialSwitch') {
            this.setState({trialSwitch: state})
        }
    }

    componentDidMount() {

        this.setState({_notificationSystem: this.refs.notificationSystem})

        const {handle} = this.props.match.params

        if (handle === 'new') {
            this.setState({loading: false, newPackage: false})

        } else {

            this.setState({loading: true})
            const url = process.env.REACT_APP_API_URL + "/PackageSvod/" + handle;

            let user = JSON.parse(localStorage.getItem('user'));
            let token = ""

            if (user) {
                token = user.token
            } else {
                this.props.dispatch(userActions.logoutUnauthorized());
            }

            let axiosConfig = {
                headers: {'X-Id-Token': token}
            };

            axios
                .get(url, axiosConfig)
                .then(res => {

                    const svodPackageObject = res.data[0];
                    const loading = false;

                    let selectedContent = []

                    if (svodPackageObject.vodpackage_assigned_content !== null) {
                        selectedContent = svodPackageObject.vodpackage_assigned_content.toString().split(",")
                    } else {
                        selectedContent = []
                    }

                    let status = svodPackageObject.vodpackage_status === 1
                    let adultSwitch = svodPackageObject.vodpackage_is_adult === 1
                    let expirySwitch = svodPackageObject.vodpackage_no_expiry === 1
                    let trialSwitch = svodPackageObject.vodpackage_trial === 1

                    this.setState({
                        back_button_text: "Back",
                        vodpackage_id: svodPackageObject.vodpackage_id,
                        vodpackage_name_short: svodPackageObject.vodpackage_name_short,
                        vodpackage_name_long: svodPackageObject.vodpackage_name_long,
                        vodpackage_description: svodPackageObject.vodpackage_description,
                        vodpackage_status: svodPackageObject.vodpackage_status,
                        vodpackage_type: svodPackageObject.vodpackage_type,
                        vodpackage_price: svodPackageObject.vodpackage_price,
                        vodpackage_image: svodPackageObject.vodpackage_image,
                        vodpackage_inactive_image: svodPackageObject.vodpackage_inactive_image,
                        vodpackage_is_adult: svodPackageObject.vodpackage_is_adult,
                        vodpackage_display_while_inactive: svodPackageObject.vodpackage_display_while_inactive,
                        vodpackage_billing_code: svodPackageObject.vodpackage_billing_code,
                        vodpackage_subscription_url: svodPackageObject.vodpackage_subscription_url,
                        vodpackage_trial: trialSwitch,
                        vodpackage_duration: svodPackageObject.vodpackage_duration,
                        statusSwitch: status,
                        assignedSvodInPackage: selectedContent,
                        adultSwitch,
                        expirySwitch,
                        loading,
                        trialSwitch,
                        svodPackageObject
                    });
                })
                .catch(error => {
                    this.setState({loading: false});
                    console.log(error);
                    //this.props.dispatch(userActions.logoutUnauthorized());
                });
        }
    }

    updateSvodPackage() {

        this.setState({loading: true})

        const {handle} = this.props.match.params
        const url = process.env.REACT_APP_API_URL + "/PackageSvod/" + handle;

        let status = this.state.statusSwitch === false ? 0 : 1
        let adultSwitch = this.state.adultSwitch === false ? 0 : 1
        let expirySwitch = this.state.expirySwitch === false ? 0 : 1
        let trialSwitch = this.state.trialSwitch === false ? 0 : 1

        let contentInPackage = []

        for (let i = 0; i < this.state.assignedSvodInPackage.length; i++) {
            contentInPackage.push(this.state.assignedSvodInPackage[i])
        }

        // Send a PUT request
        var postData = {
            svod_package: {
                vodpackage_id: this.state.vodpackage_id,
                vodpackage_name_short: this.state.vodpackage_name_short,
                vodpackage_name_long: this.state.vodpackage_name_long,
                vodpackage_description: this.state.vodpackage_description,
                vodpackage_status: status,
                vodpackage_type: this.state.vodpackage_type,
                vodpackage_price: this.state.vodpackage_price,
                vodpackage_image: this.state.vodpackage_image,
                vodpackage_inactive_image: null,
                vodpackage_is_adult: adultSwitch,
                vodpackage_no_expiry: expirySwitch,
                vodpackage_display_while_inactive: null,
                vodpackage_billing_code: this.state.vodpackage_billing_code,
                vodpackage_subscription_url: this.state.vodpackage_subscription_url,
                vodpackage_trial: trialSwitch,
                vodpackage_duration: this.state.vodpackage_duration,
                vodpackage_assigned_content: contentInPackage.join()
            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            }
        };

        axios.put(url, postData, axiosConfig)
            .then((res) => {

                if (res.data.error === 301) {
                    this.setState({
                        loading: false
                    })
                    this.notification("failed")
                } else if (res.data.error === 302) {
                    this.setState({
                        loading: false
                    })
                    this.notification("failed")
                } else {
                    this.setState({loading: false})
                    this.notification("success")
                }

            })
            .catch((err) => {
                console.log("ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());
            })

    }

    createSvodPackage() {

        this.setState({loading: true})

        const url = process.env.REACT_APP_API_URL + "/PackageSvod";

        let contentInPackage = []

        for (let i = 0; i < this.state.assignedSvodInPackage.length; i++) {
            contentInPackage.push(this.state.assignedSvodInPackage[i])
        }

        let status = this.state.statusSwitch === false ? 0 : 1
        let adultSwitch = this.state.adultSwitch === false ? 0 : 1
        let expirySwitch = this.state.expirySwitch === false ? 0 : 1
        let trialSwitch = this.state.trialSwitch === false ? 0 : 1

        var postData = {
            svod_package: {
                vodpackage_name_short: this.state.vodpackage_name_short,
                vodpackage_name_long: this.state.vodpackage_name_long,
                vodpackage_description: this.state.vodpackage_description,
                vodpackage_status: status,
                vodpackage_type: this.state.vodpackage_type,
                vodpackage_price: this.state.vodpackage_price,
                vodpackage_image: this.state.vodpackage_image,
                vodpackage_inactive_image: null,
                vodpackage_is_adult: adultSwitch,
                vodpackage_no_expiry: expirySwitch,
                vodpackage_trial: trialSwitch,
                vodpackage_display_while_inactive: null,
                vodpackage_billing_code: this.state.vodpackage_billing_code,
                vodpackage_assigned_content: contentInPackage.join(),
                vodpackage_subscription_url: this.state.vodpackage_subscription_url,
                vodpackage_duration: this.state.vodpackage_duration

            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            }
        };

        axios.post(url, postData, axiosConfig)
            .then((res) => {
                this.setState({loading: false})

                if (res.data.error === 301) {
                    this.setState({
                        loading: false
                    })
                    this.notification("failed")
                } else if (res.data.error === 302) {
                    this.setState({
                        loading: false
                    })
                    this.notification("failed")
                } else {
                    const svodPackageObject = res.data;
                    this.setState({vodpackage_id: svodPackageObject.vodpackage_id})
                    this.setSvodPackageRedirect();
                    this.notification("success")
                }
            })
            .catch((err) => {
                console.log("ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());
            })

    }

    setSaveModal = (value, action) => {

        this.setState({
            showSaveModal: value

        })

        if (action === "save") {
            this.setState({loading: true})

            const {handle} = this.props.match.params

            if (handle === 'new') {
                this.createSvodPackage();
            } else {
                this.updateSvodPackage();
            }
        }
    }

    cancelSvodPackageEdit() {
        this.setRedirect();
    }

    setSvodPackageRedirect = () => {
        this.setState({
            vodPackageRedirect: true,
            back_button_text: "Back"
        })
    }

    renderSaveModal = () => {

        if (this.state.showSaveModal) {
            return <ModalSave
                headerMessage={"SVOD Package: "}
                bodyMessage={"this action will save all changes, click close to cancel saving"}
                handlerFromParent={this.setSaveModal}
                handlerID={this.state.vodpackage_id === 0 ? '' : this.state.vodpackage_id}
            />
        }

    }

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    renderRedirect = () => {

        let path = "/packages/svod-package";
        let pathvodpackage = "/edit-svod-package/" + this.state.vodpackage_id;

        if (this.state.redirect) {
            return <Redirect push to={path}/>
        }

        if (this.state.vodPackageRedirect) {

            this.setState({
                vodPackageRedirect: false,
                redirect: false
            })

            return <Redirect push to={pathvodpackage}/>
        }

    }

    renderSelection = () => {

        if (this.state.vodpackage_type === 'SERIES') {
            return (
                <Card
                    content={
                        <div>
                            <h5>Series Selection</h5>
                            <legend/>
                            <Grid fluid>
                                <Row>
                                    <DualListBoxPackageSvodSeries
                                        seriesInPackage={this.state.assignedSvodInPackage}
                                        packageID={this.state.vodpackage_id}
                                        packageType={"SERIES"}
                                        handlerFromParent={this.handleSvodPackageChannelChange}

                                    />
                                </Row>
                            </Grid>
                        </div>
                    }
                />
            )
        }

        if (this.state.vodpackage_type === 'MOVIES') {
            return (
                <Card
                    content={
                        <div>
                            <h5>Movies Selection</h5>
                            <legend/>
                            <Grid fluid>
                                <Row>
                                    <DualListBoxPackageSvodMovies
                                        moviesInPackage={this.state.assignedSvodInPackage}
                                        packageID={this.state.vodpackage_id}
                                        packageType={"MOVIES"}
                                        handlerFromParent={this.handleSvodPackageChannelChange}

                                    />
                                </Row>
                            </Grid>
                        </div>
                    }
                />
            )
        }


    }

    renderDurationFields = () => {

        if(!this.state.expirySwitch){
            return (
                <FormGroup>
                    <ControlLabel>Duration (Days)</ControlLabel>
                    <FormControl placeholder="Enter the duration"
                                 type="number"
                                 name={"vodpackage_duration"}
                                 disabled={this.state.loading}
                                 onChange={this.handleInputChange}
                                 value={this.state.vodpackage_duration || ""}/>
                </FormGroup>
            );
        }


    }

    notification(status) {

        if (status === "success") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>SVOD Package has been updated</b>
                    </div>
                ),
                level: 'success',
                position: 'tr',
                autoDismiss: 5,
            });
        }

        if (status === "failed") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>failed to update SVOD Package</b>
                    </div>
                ),
                level: 'error',
                position: 'tr',
                autoDismiss: 10,
            });
        }

    }

    render() {
        return (
            <div className="main-content">

                <NotificationSystem ref="notificationSystem" style={style}/>

                {this.renderRedirect()}
                {this.renderSaveModal()}

                <div className="sweet-loader">
                    <ScaleLoader
                        css={override}
                        color={'#797979'}
                        loading={this.state.loading}
                        //loading={true}
                    />
                </div>

                <Grid fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6}>
                            <Card
                                content={
                                    <div>
                                        <h5>SVOD Package Information</h5>
                                        <legend/>

                                        <FormGroup>
                                            <Row>
                                                <Col md={3}>
                                                    <ControlLabel>Status</ControlLabel>
                                                    <br/>
                                                    <Switch
                                                        name={"statusSwitch"}
                                                        disabled={this.state.loading}
                                                        value={this.state.statusSwitch}
                                                        onChange={(el, state) => this.handleSwitch(el, state)}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <ControlLabel>No Expiry</ControlLabel>
                                                    <br/>
                                                    <Switch
                                                        name={"expirySwitch"}
                                                        disabled={this.state.loading}
                                                        value={this.state.expirySwitch}
                                                        onChange={(el, state) => this.handleSwitch(el, state)}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <ControlLabel>Trial</ControlLabel>
                                                    <br/>
                                                    <Switch
                                                        name={"trialSwitch"}
                                                        disabled={this.state.loading || this.state.expirySwitch}
                                                        value={this.state.trialSwitch}
                                                        onChange={(el, state) => this.handleSwitch(el, state)}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <ControlLabel>Adult</ControlLabel>
                                                    <br/>
                                                    <Switch
                                                        name={"adultSwitch"}
                                                        disabled={this.state.loading}
                                                        value={this.state.adultSwitch}
                                                        onChange={(el, state) => this.handleSwitch(el, state)}
                                                    />
                                                </Col>
                                            </Row>
                                        </FormGroup>


                                        <FormGroup>
                                            <ControlLabel>Package Type</ControlLabel>
                                            <Select
                                                value={this.state.vodpackage_type}
                                                onChange={this.handleSvodPackageTypeChange}
                                                options={packageType}
                                                disabled={this.state.newPackage}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>Short Name</ControlLabel>
                                            <FormControl placeholder="Enter Short Name"
                                                         type="text"
                                                         name={"vodpackage_name_short"}
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         value={this.state.vodpackage_name_short || ""}/>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>Reference ID</ControlLabel>
                                            <FormControl placeholder="Enter the price"
                                                         type="text"
                                                         name={"vodpackage_billing_code"}
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         value={this.state.vodpackage_billing_code || ""}/>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>Price</ControlLabel>
                                            <FormControl placeholder="Enter the price"
                                                         type="number"
                                                         name={"vodpackage_price"}
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         value={this.state.vodpackage_price || ""}/>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>External URL</ControlLabel>
                                            <InputGroup>
                                                <InputGroup.Addon style={{backgroundColor:"lightgray"}}>
                                                    <Glyphicon glyph="globe"/>
                                                </InputGroup.Addon>
                                                <FormControl placeholder={this.props.t('packages_svod_edit.vodpackage_subscription_url_placeholder', {framework: "react-i18next"})}
                                                             type="text"
                                                             name="vodpackage_subscription_url"
                                                             disabled={this.state.loading}
                                                             onChange={this.handleInputChange}
                                                             value={this.state.vodpackage_subscription_url || ""}
                                                />
                                            </InputGroup>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>Long Name</ControlLabel>
                                            <FormControl placeholder="Enter Long Name"
                                                         type="text"
                                                         name={"vodpackage_name_long"}
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         value={this.state.vodpackage_name_long || ""}/>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>Description</ControlLabel>
                                            <FormControl componentClass="textarea"
                                                         type="text"
                                                         rows={4}
                                                         name="vodpackage_description"
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         placeholder="Enter the description"
                                                         value={this.state.vodpackage_description || ""}
                                            />
                                        </FormGroup>

                                        {this.renderDurationFields()}

                                    </div>
                                }
                                ftTextCenter
                                legend={
                                    <div className="buttons-with-margin">
                                        <Button bsStyle="info" fill wd
                                                name={"saveButton"}
                                                disabled={this.state.loading}
                                                onClick={() => this.setState({showSaveModal: true})}
                                        >
                                            Save
                                        </Button>
                                        <Button bsStyle="default" fill wd
                                                name={"cancelButton"}
                                                disabled={this.state.loading}
                                                onClick={this.cancelSvodPackageEdit.bind(this)}>
                                            {this.state.back_button_text}
                                        </Button>
                                    </div>
                                }
                            />
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={6}>
                            <Card
                                content={
                                    <div>
                                        <h5>Assets</h5>
                                        <legend/>
                                        <Grid fluid>
                                            <Row>
                                                <Col md={6}>
                                                    <div className="text-center">
                                                        <p>Active</p>
                                                    </div>
                                                    <ImageUpload
                                                        imageName={this.state.cat_image}
                                                        imageType="live_tv_cat"
                                                        imagePosition={"cat_image"}
                                                        imageTemp={this.state.cat_image_temp}
                                                        imageFolderTemp={this.state.cat_image_foldåer_temp}
                                                        handlerFromParent={this.handleChildImageSelect}
                                                    />
                                                </Col>
                                                <Col md={6}>
                                                    <div className="text-center">
                                                        <p>Inactive</p>
                                                    </div>
                                                    <ImageUpload
                                                        imageName={this.state.cat_image}
                                                        imageType="live_tv_cat"
                                                        imagePosition={"cat_image"}
                                                        imageTemp={this.state.cat_image_temp}
                                                        imageFolderTemp={this.state.cat_image_folder_temp}
                                                        handlerFromParent={this.handleChildImageSelect}
                                                    />
                                                </Col>
                                            </Row>
                                        </Grid>
                                    </div>
                                }
                            />
                        </Col>


                        <Col xs={12} sm={12} md={12} lg={6}>
                            {this.renderSelection()}
                        </Col>

                    </Row>
                </Grid>


            </div>
        );
    }
}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedEditPackagesSvod = connect(mapStateToProps)(EditPackagesSvod);
const connectedEditPackagesSvodTrans = withNamespaces('common')(connectedEditPackagesSvod);
export {connectedEditPackagesSvodTrans as EditPackagesSvod};