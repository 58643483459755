import React, {Component} from "react";
import {Redirect} from "react-router";
import Switch from "react-bootstrap-switch";
import axios from "axios/index";
import {Grid, Row, Col, FormGroup, ControlLabel, FormControl, ButtonGroup} from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import {css} from "@emotion/core";
import {ScaleLoader} from 'react-spinners';
import ModalSave from "../../components/CustomModals/ModalSave";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import {DataMusicTracks} from "../../components/Middleware/DataMusicTracks";
import SearchBarMusicAlbums from "../../components/LastFm/SearchBarMusicAlbums";
import TagsInput from 'react-tagsinput';
import NotificationSystem from "react-notification-system";
import {style} from "../../variables/Variables";

const override = css`
    position: fixed;
    left: 50%;
    top: 35%;
    display: block;
    z-index: 1000;
    height: 31px;
    width: 100px;
    `;


class EditMusicAlbum extends Component {

    constructor(props) {
        super(props);
        this.state = {

            _notificationSystem: null,

            albumArray: [],
            loading: true,
            redirect: false,
            back_button_text: "Cancel",

            showSaveModal: false,

            showMusicTracks: false,

            mbid_status: false,
            musicAlbumRedirect: false,
            albumStatus: false,
            albumOfflineDownload: false,

            album_id: null,
            album_position: null,
            album_status: null,
            album_offline_download: null,
            album_name: null,
            album_artist: null,
            album_mbid: null,
            album_url: null,
            album_releasedate: null,
            album_poster: null,
            album_genre: null,
            album_genre_id: null,
            album_country: null,
            album_composer: null,
            album_summary: null,


            album_poster_temp: false,
            album_poster_folder_temp: "",

            tempTrackArray: [],
            albumTags: [],
            newAlbum: false

        };

        this.handleInputChange = this.handleInputChange.bind(this);

        this.handleChildImageSelect = this.handleChildImageSelect.bind(this);
        this.handleChildGenreSelect = this.handleChildGenreSelect.bind(this);
        this.handleChildLastFmSelect = this.handleChildLastFmSelect.bind(this);
        this.handleMusicTags = this.handleMusicTags.bind(this)
    }

    componentDidMount() {

        this.setState({_notificationSystem: this.refs.notificationSystem})

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        const {handle} = this.props.match.params
        const url = process.env.REACT_APP_API_URL + "/Music/" + handle;

        if (handle === 'new') {
            this.setState({loading: false, newAlbum: true})
            this.getPosition();
        } else {

            axios
                .get(url, axiosConfig)
                .then(res => {

                    const albumArray = res.data[0];
                    const loading = false;

                    let albumStatus = albumArray.album_status === 0 ? false : true;
                    let albumTags = albumArray.album_tags !== null ? albumArray.album_tags.split(',') : []

                    this.setState({

                        showMusicTracks: true,
                        back_button_text: "Back",

                        albumArray,
                        albumTags,
                        albumStatus,
                        loading,

                        album_id: albumArray.album_id,
                        album_position: albumArray.album_position,
                        album_status: albumStatus,
                        album_name: albumArray.album_name,
                        album_artist: albumArray.album_artist,
                        album_mbid: albumArray.album_mbid,
                        album_url: albumArray.album_url,
                        album_releasedate: albumArray.album_releasedate,
                        album_poster: albumArray.album_poster,
                        album_genre: albumArray.album_genre,
                        album_country: albumArray.album_country,
                        album_composer: albumArray.album_composer,
                        album_summary: albumArray.album_summary

                    });
                })
                .catch(error => {
                    this.setState({loading: false,});
                    this.props.dispatch(userActions.logoutUnauthorized());
                    console.log(error);
                });
        }


    }

    handleChildLastFmSelect(data) {

        if (data) {

            if (data.data.album !== undefined) {

                let albumData = data.data.album;
                let name = albumData.name;
                let artist = albumData.artist;
                let mbid = albumData.mbid;
                let url = albumData.url;
                //let listeners = albumData.listeners;
                //let playcount = albumData.playcount;
                let image = albumData.image[3]['#text'];

                console.log(image)

                let tracks = albumData.tracks.track;
                let tags = albumData.tags.tag;

                //let tracksInAlbum = tracks.length
                let albumDuration = 0

                let album_summary = albumData.wiki.summary

                let albumTags = []
                //let tracksArray = []

                for (let i = 0; i < tracks.length; i++) {
                    //console.log(tracks[i].name + " " + tracks[i].duration)
                    albumDuration = albumDuration + tracks[i].duration
                }

                for (let i = 0; i < tags.length; i++) {

                    if (tags[i].name === 'albums I own') {
                        //do nothing
                    } else if (tags[i].name === 'favourite albums') {
                        //do nothing
                    } else {
                        //console.log(tags[i].name)
                        albumTags.push(tags[i].name)
                    }
                }

                this.setState({
                    mbid_status: true,
                    album_name: name,
                    album_artist: artist,
                    album_mbid: mbid,
                    album_url: url,
                    album_poster: image,
                    album_summary: album_summary,
                    tempTrackArray: tracks,
                    albumTags: albumTags

                });
            }
        }

    }

    handleMusicTags(albumTags) {
        // console.log(albumTags)
        this.setState({albumTags});
    }

    handleChildGenreSelect(data) {

        // console.log(data)

        let genreIDs = []
        let genreNames = []

        for (let i = 0; i < data.length; i++) {
            genreIDs.push(data[i].value)
        }

        for (let i = 0; i < data.length; i++) {
            genreNames.push(data[i].label)
        }

        this.setState({
            album_genre_id: data === null ? null : genreIDs,
            album_genre: data === null ? [] : genreNames
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'statusSwitch' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSwitch(elem, state) {

        if (elem.props.name === 'albumStatus') {
            this.setState({albumStatus: state})
        }
        if (elem.props.name === 'albumOfflineDownload') {
            this.setState({albumOfflineDownload: state})
        }
    }

    submitPosterImage(filename_poster) {

        // console.log("********************")
        // console.log(filename_poster)
        // console.log("********************")


        const imageUploadUrl = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

        if (this.state.album_poster_temp) {

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.album_poster_folder_temp);
            bodyFormData.set('location', "music_list");
            bodyFormData.set('filename', filename_poster);

            axios.post(imageUploadUrl, bodyFormData)
                .then((res) => {
                    this.setState({album_poster_temp: false})
                })
                .catch((err) => {
                    console.log("album_poster - AXIOS ERROR: ", err);
                })
        }

    }

    submitLastFmImage() {

        if (this.state.mbid_status) {

            const url = process.env.REACT_APP_LASTFM_IMAGE_API_URL;

            const imgUrl = this.state.album_poster;
            const filename = this.state.album_poster.replace("https://lastfm.freetls.fastly.net/i/u/300x300/", '')

            // console.log(imgUrl)
            // console.log(filename)

            let bodyFormData = new FormData();
            bodyFormData.set('filename', filename);
            bodyFormData.set('location', "music_list");
            bodyFormData.set('url', imgUrl);

            axios.post(url, bodyFormData)
                .then((res) => {
                })
                .catch((err) => {
                    console.log("ERROR: ", err);
                })
        }

    }

    updateMusicAlbum() {

        this.setState({loading: true})
        this.submitLastFmImage()

        const url = process.env.REACT_APP_API_URL + "/Music/" + this.state.album_id;

        //RENAME FUNCTIONS START POSTER NORMAL

        let filename_poster = ""
        if (!this.state.mbid_status) {
            if (this.state.album_poster_temp) {
                let timeUTC = new Date().getTime()
                filename_poster = timeUTC + "_" + this.state.album_poster
                this.setState({album_poster: filename_poster})
                this.submitPosterImage(filename_poster);
            } else {
                filename_poster = this.state.album_poster
            }
        } else {
            filename_poster = this.state.album_poster.replace("https://lastfm.freetls.fastly.net/i/u/300x300/", '')
        }


        let albumStatus = this.state.albumStatus === true ? 1 : 0;

        // console.log("TAGS LENGHT: " + this.state.albumTags.length);
        // Send a PUT request
        let postData = {
            music_album: {
                type: "update_music_album",
                album_id: this.state.album_id,
                album_position: this.state.album_position,
                album_status: albumStatus,
                album_name: this.state.album_name,
                album_artist: this.state.album_artist,
                album_mbid: this.state.album_mbid,
                album_url: this.state.album_url,
                album_releasedate: this.state.album_releasedate,
                album_poster: filename_poster,
                album_genre: this.state.album_genre,
                album_country: this.state.album_country,
                album_composer: this.state.album_composer,
                album_tracks: this.state.tempTrackArray,
                album_tags: this.state.albumTags.length === 0 ? null : this.state.albumTags.join(","),
                album_summary: this.state.album_summary
            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        axios.put(url, postData, axiosConfig)
            .then((res) => {
                this.setState({saving: false})
                this.setState({loading: false})
                this.notification("success")
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.notification("failed")
            })

    }

    createMusicAlbum() {

        this.submitLastFmImage()

        this.setState({loading: true})
        const url = process.env.REACT_APP_API_URL + "/Music";

        //RENAME FUNCTIONS START POSTER NORMAL
        let filename_poster = ""
        if (!this.state.mbid_status) {
            if (this.state.album_poster_temp) {
                let timeUTC = new Date().getTime()
                filename_poster = timeUTC + "_" + this.state.album_poster
                this.setState({album_poster: filename_poster})
                this.submitPosterImage(filename_poster);
            } else {
                filename_poster = this.state.album_poster
            }
        } else {
            filename_poster = this.state.album_poster.replace("https://lastfm.freetls.fastly.net/i/u/300x300/", '')
        }

        let albumStatus = this.state.albumStatus === true ? 1 : 0;

        // Send a PUT request
        let postData = {
            music_album: {
                type: "create_music_album",
                album_position: this.state.album_position,
                album_status: albumStatus,
                album_name: this.state.album_name,
                album_artist: this.state.album_artist,
                album_mbid: this.state.album_mbid,
                album_url: this.state.album_url,
                album_releasedate: this.state.album_releasedate,
                album_poster: filename_poster,
                album_genre: this.state.album_genre,
                album_country: this.state.album_country,
                album_composer: this.state.album_composer,
                album_tracks: this.state.tempTrackArray,
                album_tags: this.state.albumTags.length === 0 ? null : this.state.albumTags.join(","),
                album_summary: this.state.album_summary
            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        axios.post(url, postData, axiosConfig)
            .then((res) => {
                const albumArray = res.data;
                this.setState({album_id: albumArray.album_id, loading: false, newAlbum: false})
                this.setMusicAlbumRedirect();
                this.notification("success")
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.notification("failed")
            })

    }

    handleChildImageSelect(folder, filename, imagePosition) {

        if (imagePosition === "album_poster") {
            this.setState({
                album_poster_temp: filename !== null,
                album_poster: filename,
                album_poster_folder_temp: folder
            });
        }

    }

    getPosition() {

        const url = process.env.REACT_APP_API_URL + "/Music/position";

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        axios
            .get(url, axiosConfig)
            .then(res => {
                const musicAlbumPosition = res.data[0];
                this.setState({
                    album_position: musicAlbumPosition.MusicAlbumPosition
                });
            })
            .catch(error => {
                this.props.dispatch(userActions.logoutUnauthorized());
                console.log(error);
            });

    }

    cancelMusicAlbumEdit() {
        this.setRedirect();
    }

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    setMusicAlbumRedirect = () => {
        this.setState({
            musicAlbumRedirect: true,
            back_button_text: "Back"
        })
    }

    renderRedirect = () => {

        let path = "/music";
        let pathAlbum = "/edit-music-album/" + this.state.album_id;

        if (this.state.redirect) {
            // this.setState({
            //     redirect: false,
            //     musicAlbumRedirect: false
            // })
            return <Redirect push to={path}/>
        }

        if (this.state.musicAlbumRedirect) {
            this.setState({
                redirect: false,
                musicAlbumRedirect: false
            })
            return <Redirect push to={pathAlbum}/>
        }

    }

    setSaveModal = (value, action) => {

        this.setState({
            showSaveModal: value

        })

        if (action === "save") {
            this.setState({saving: true})

            const {handle} = this.props.match.params

            if (handle === 'new') {
                this.createMusicAlbum();
            } else {
                this.updateMusicAlbum();
            }
        }
    }

    renderSaveModal = () => {

        if (this.state.showSaveModal) {
            return <ModalSave
                headerMessage={"Music Album: "}
                bodyMessage={"this action will save all changes, click close to cancel saving"}
                handlerFromParent={this.setSaveModal}
                handlerID={this.state.apps_id}
            />
        }
    }

    notification(status) {

        if (status === "success") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>Album has been updated</b>
                    </div>
                ),
                level: 'success',
                position: 'tr',
                autoDismiss: 5,
            });
        }

        if (status === "failed") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>failed to update Album</b>
                    </div>
                ),
                level: 'error',
                position: 'tr',
                autoDismiss: 10,
            });
        }

    }

    renderSearch = () => {

        if (process.env.REACT_APP_LASTFM === "1") {
            if (this.state.newAlbum) {
                return (
                    <FormGroup>
                        <SearchBarMusicAlbums handlerFromParent={this.handleChildLastFmSelect}/>
                    </FormGroup>)
            }

            if (!this.state.newAlbum && this.state.album_mbid !== null) {
                return (
                    <FormGroup>
                        <SearchBarMusicAlbums handlerFromParent={this.handleChildLastFmSelect}/>
                    </FormGroup>)
            }
        }
    }

    render() {
        return (
            <div className="main-content">

                <NotificationSystem ref="notificationSystem" style={style}/>

                {this.renderRedirect()}
                {this.renderSaveModal()}

                <div className="sweet-loader">
                    <ScaleLoader
                        css={override}
                        color={'#797979'}
                        loading={this.state.loading}
                    />
                </div>

                <Card
                    content={
                        <div>
                            <Grid fluid>
                                <Row>
                                    <Col md={6}>
                                        <ButtonGroup>
                                            <Button
                                                onClick={() => this.setState({showSaveModal: true})}
                                                bsStyle="info"
                                                fill wd
                                                disabled={this.state.loading}
                                                name={"saveButton"}>
                                                Save
                                            </Button>
                                            <Button
                                                bsStyle="default"
                                                fill wd name={"cancelButton"}
                                                onClick={this.cancelMusicAlbumEdit.bind(this)}>
                                                {this.state.back_button_text}
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <div className="pull-right">
                                                {this.renderSearch()}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Grid>
                        </div>
                    }
                />

                <Card
                    content={
                        <div>
                            <Grid fluid>
                                <h5>Album Info</h5>
                                <legend/>
                                <Col sm={3}>
                                    <FormGroup>
                                        {/*<ImageUpload*/}
                                        {/*imageName={this.state.album_poster}*/}
                                        {/*imageType="music_list"*/}
                                        {/*imagePosition={"album_poster"}*/}
                                        {/*imageTemp={this.state.album_poster_temp}*/}
                                        {/*imageFolderTemp={this.state.album_poster_folder_temp}*/}
                                        {/*handlerFromParent={this.handleChildImageSelect}*/}
                                        {/*/>*/}
                                        <ImageUpload
                                            imageName={this.state.album_poster}
                                            imageType="music_list"
                                            imagePosition={"album_poster"}
                                            imageTemp={this.state.album_poster_temp}
                                            imageFolderTemp={this.state.album_poster_folder_temp}
                                            imageSource={this.state.mbid_status === undefined ? false : this.state.mbid_status}
                                            handlerFromParent={this.handleChildImageSelect}
                                            imageTmdbType={"music_poster"}

                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>Summary</ControlLabel>
                                        <FormControl componentClass="textarea"
                                                     type="text"
                                                     rows={15}
                                                     name="album_summary"
                                                     disabled={this.state.loading}
                                                     onChange={this.handleInputChange}
                                                     placeholder="Summary"
                                                     value={this.state.album_summary || ""}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col sm={9}>
                                    <Row>
                                        <Col sm={2}>
                                            <FormGroup>
                                                <ControlLabel>Position</ControlLabel>
                                                <FormControl placeholder="album_position"
                                                             type="number"
                                                             name="album_position"
                                                             disabled={this.state.loading}
                                                             onChange={this.handleInputChange}
                                                             value={this.state.album_position || "1"}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={2}>
                                            <FormGroup>
                                                <ControlLabel>Status</ControlLabel>
                                                <br/>
                                                <Switch
                                                    onChange={(el, state) => this.handleSwitch(el, state)}
                                                    name='albumStatus'
                                                    disabled={this.state.loading}
                                                    value={this.state.albumStatus}
                                                    defaultValue={false}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={3}>
                                            <FormGroup>
                                                <ControlLabel>Offline Download</ControlLabel>
                                                <br/>
                                                <Switch
                                                    onChange={(el, state) => this.handleSwitch(el, state)}
                                                    name='albumOfflineDownload'
                                                    disabled={this.state.loading}
                                                    value={this.state.albumOfflineDownload}
                                                    defaultValue={false}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={2}>
                                        </Col>
                                        <Col sm={3}>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm={4}>
                                            <FormGroup>
                                                <ControlLabel>Album Name</ControlLabel>
                                                <FormControl placeholder="album_name"
                                                             type="text"
                                                             name="album_name"
                                                             disabled={this.state.loading}
                                                             onChange={this.handleInputChange}
                                                             value={this.state.album_name || ""}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={4}>
                                            <FormGroup>
                                                <ControlLabel>Artist</ControlLabel>
                                                <FormControl placeholder="album_artist"
                                                             type="text"
                                                             name="album_artist"
                                                             disabled={this.state.loading}
                                                             onChange={this.handleInputChange}
                                                             value={this.state.album_artist || ""}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={4}>
                                            <FormGroup>
                                                <ControlLabel>Composer</ControlLabel>
                                                <FormControl placeholder="Composer"
                                                             type="text"
                                                             name="album_composer"
                                                             disabled={this.state.loading}
                                                             onChange={this.handleInputChange}
                                                             value={this.state.album_composer || ""}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm={2}>
                                            <FormGroup>
                                                <ControlLabel>Country</ControlLabel>
                                                <FormControl placeholder="Country"
                                                             type="text"
                                                             name="album_country"
                                                             disabled={this.state.loading}
                                                             onChange={this.handleInputChange}
                                                             value={this.state.album_country || ""}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={10}>
                                            <FormGroup>
                                                <ControlLabel>Tags</ControlLabel>
                                                <TagsInput
                                                    value={this.state.albumTags}
                                                    disabled={this.state.loading}
                                                    onChange={this.handleMusicTags}
                                                    tagProps={{className: 'react-tagsinput-tag tag-fill tag-blue'}}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm={12}>
                                            <DataMusicTracks
                                                handlerMbidID={this.state.album_mbid}
                                                handlerMbidStatus={this.state.mbid_status}
                                                handlerTempTracks={this.state.tempTrackArray}
                                                handlerID={this.state.album_id}
                                            />
                                        </Col>
                                    </Row>


                                </Col>

                            </Grid>
                        </div>
                    }
                />


            </div>
        )
    }
}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedEditMusicAlbum = connect(mapStateToProps)(EditMusicAlbum);
const connectedEditMusicAlbumTrans = withNamespaces('common')(connectedEditMusicAlbum);
export {connectedEditMusicAlbumTrans as EditMusicAlbum};
