import React, {Component} from "react";
import {FormGroup} from "react-bootstrap";
import Button from "../../components/CustomButton/CustomButton";

import {FilePond, registerPlugin} from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import 'filepond/dist/filepond.min.css';
import {userActions} from "../../authentication/_actions";
import axios from "axios";

import {Lightbox} from "react-modal-image";
import {isMobile} from "react-device-detect";

registerPlugin(FilePondPluginFileValidateType);

export class ImageUpload extends Component {

    constructor(props) {
        super(props);

        this.handleImageSelect = this.handleImageSelect.bind(this);

        this.state = {
            // Set initial files
            files: [],
            preview: false,
            previewUrl: null
        };

        this.openLightbox = this.openLightbox.bind(this);
        this.closeLightbox = this.closeLightbox.bind(this);

    }

    handleImageSelect(folder, filename) {
        this.props.handlerFromParent(folder, filename, this.props.imagePosition);
    }

    handleImageDelete() {

        let url = "";

        if (this.props.imageName !== null && this.props.imageType !== null) {

            if (this.props.imageSubLocation) {
                if (this.props.imageSubLocation === "vod_episode") {
                    url = process.env.REACT_APP_API_URL + "/Global/Image/vod_episode/" + this.props.imageName;
                } else if (this.props.imageSubLocation === "vod_season") {
                    url = process.env.REACT_APP_API_URL + "/Global/Image/vod_season/" + this.props.imageName;
                } else {
                    url = process.env.REACT_APP_API_URL + "/Global/Image/" + this.props.imageType + "/" + this.props.imageName;
                }
            } else {
                url = process.env.REACT_APP_API_URL + "/Global/Image/" + this.props.imageType + "/" + this.props.imageName;
            }

            let user = JSON.parse(localStorage.getItem('user'));

            let token = ""

            if (user) {
                token = user.token
            } else {
                this.props.dispatch(userActions.logoutUnauthorized());
            }

            let axiosConfig = {
                headers: {'X-Id-Token': token}
            };

            axios
                .delete(url, axiosConfig)
                .then(res => {

                    // console.log(res);
                    // console.log(res.data);
                    // console.log(res.data.status);

                    if (res.data.status === 200) {
                        // console.log("Success");
                        this.handleImageSelect(this.props.imageType, null);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            console.log("Nothing to delete")
        }

    }

    handleImageLoad(filename, imageType, imageState, imageFolderTemp, imageSource, imageTmdbType) {

        // console.log(filename)
        // console.log(imageType)
        // console.log(imageState)
        // console.log(imageFolderTemp)
        // console.log(imageSource)
        // console.log(imageTmdbType)

        let imageURL = "";

        if (filename === null) {
            imageURL = process.env.REACT_APP_ASSET_URL + "/global/noimage.png"
        } else if (filename === "comp_") {
            imageURL = process.env.REACT_APP_ASSET_URL + "/global/noimage.png"
        } else if (filename === "") {
            imageURL = process.env.REACT_APP_ASSET_URL + "/global/noimage.png"
        } else if (filename === undefined) {
            imageURL = process.env.REACT_APP_ASSET_URL + "/global/noimage.png"
        } else if (filename.includes('https://')) {
            imageURL = filename
        } else {

            if (imageState) {
                imageURL = process.env.REACT_APP_ASSET_URL + "/tmp/" + imageFolderTemp + "/" + filename;
            } else {

                //TMDB CASE
                if (imageSource) {
                    if (imageTmdbType === "poster_path") {
                        imageURL = "https://image.tmdb.org/t/p/w500" + filename;
                    } else if (imageTmdbType === "backdrop_path") {
                        imageURL = "https://image.tmdb.org/t/p/w1280" + filename;
                    } else if (imageTmdbType === "poster_path_wide") {
                        imageURL = "https://image.tmdb.org/t/p/w300" + filename;
                    } else if (imageTmdbType === "music_poster") {
                        imageURL = filename;
                    }
                } else if (imageType === "live_tv") {
                    imageURL = process.env.REACT_APP_ASSET_URL + "/live_tv/" + filename;
                } else if (imageType === "live_tv_cat") {
                    imageURL = process.env.REACT_APP_ASSET_URL + "/live_tv_cat/" + filename;
                } else if (imageType === "live_radio") {
                    imageURL = process.env.REACT_APP_ASSET_URL + "/live_radio/" + filename;
                } else if (imageType === "live_radio_cat") {
                    imageURL = process.env.REACT_APP_ASSET_URL + "/live_radio_cat/" + filename;
                } else if (imageType === "live_match") {
                    imageURL = process.env.REACT_APP_ASSET_URL + "/live_match/" + filename;
                } else if (imageType === "live_match_cat") {
                    imageURL = process.env.REACT_APP_ASSET_URL + "/live_match_cat/" + filename;
                } else if (imageType === "live_events") {
                    imageURL = process.env.REACT_APP_ASSET_URL + "/live_events/" + filename;
                } else if (imageType === "live_events_cat") {
                    imageURL = process.env.REACT_APP_ASSET_URL + "/live_events_cat/" + filename;
                } else if (imageType === "apps_list") {
                    imageURL = process.env.REACT_APP_ASSET_URL + "/apps_list/" + filename;
                } else if (imageType === "apps_cat") {
                    imageURL = process.env.REACT_APP_ASSET_URL + "/apps_cat/" + filename;
                } else if (imageType === "vod_tvod") {
                    imageURL = process.env.REACT_APP_ASSET_URL + "/vod_tvod/" + filename;
                } else if (imageType === "vod_svod") {
                    imageURL = process.env.REACT_APP_ASSET_URL + "/vod_svod/" + filename;
                } else if (imageType === "vod_avod") {
                    imageURL = process.env.REACT_APP_ASSET_URL + "/vod_avod/" + filename;
                } else if (imageType === "vod_series") {
                    imageURL = process.env.REACT_APP_ASSET_URL + "/vod_series/" + filename;
                } else if (imageType === "device_menu") {
                    imageURL = process.env.REACT_APP_ASSET_URL + "/device_menu/" + filename;
                } else if (imageType === "music_list") {
                    imageURL = process.env.REACT_APP_ASSET_URL + "/music_list/" + filename;
                } else if (imageType === "karaoke_list") {
                    imageURL = process.env.REACT_APP_ASSET_URL + "/karaoke_list/" + filename;
                } else if (imageType === "properties") {
                    imageURL = process.env.REACT_APP_ASSET_URL + "/properties/" + filename;
                } else if (imageType === "global") {
                    imageURL = process.env.REACT_APP_ASSET_URL + "/global/" + filename;
                } else {
                    imageURL = process.env.REACT_APP_ASSET_URL + "/global/noimage.png"
                }

            }

        }

        return imageURL

    }

    renderImage = () => {

        if (this.props.imageType === 'live_tv') {
            return (
                <img className="img-responsive center-block"
                     style={{maxHeight: '180px', maxWidth: '90%', cursor: 'pointer'}}
                     src={this.handleImageLoad(this.props.imageName, this.props.imageType, this.props.imageTemp, this.props.imageFolderTemp, this.props.imageSource, this.props.imageTmdbType)}
                     alt={this.props.imageName}
                     onClick={() => this.openLightbox(this.handleImageLoad(this.props.imageName, this.props.imageType, this.props.imageTemp, this.props.imageFolderTemp, this.props.imageSource, this.props.imageTmdbType))}
                     onError={(e) => {
                         e.target.onerror = null;
                         e.target.src = process.env.REACT_APP_ASSET_URL + "/global/landscape_image_broken.png"
                     }}
                />
            );
        } else if (this.props.imageType === 'live_match') {
            return (
                <img className="img-responsive center-block"
                     style={{maxHeight: '180px', maxWidth: '90%', cursor: 'pointer'}}
                     src={this.handleImageLoad(this.props.imageName, this.props.imageType, this.props.imageTemp, this.props.imageFolderTemp, this.props.imageSource, this.props.imageTmdbType)}
                     alt={this.props.imageName}
                     onClick={() => this.openLightbox(this.handleImageLoad(this.props.imageName, this.props.imageType, this.props.imageTemp, this.props.imageFolderTemp, this.props.imageSource, this.props.imageTmdbType))}
                     onError={(e) => {
                         e.target.onerror = null;
                         e.target.src = process.env.REACT_APP_ASSET_URL + "/global/landscape_image_broken.png"
                     }}
                />
            );
        } else if (this.props.imageType === 'live_events') {
            return (
                <img className="img-responsive center-block"
                     style={{maxHeight: '150px', maxWidth: '90%', cursor: 'pointer'}}
                     src={this.handleImageLoad(this.props.imageName, this.props.imageType, this.props.imageTemp, this.props.imageFolderTemp, this.props.imageSource, this.props.imageTmdbType)}
                     alt={this.props.imageName}
                     onClick={() => this.openLightbox(this.handleImageLoad(this.props.imageName, this.props.imageType, this.props.imageTemp, this.props.imageFolderTemp, this.props.imageSource, this.props.imageTmdbType))}
                     onError={(e) => {
                         e.target.onerror = null;
                         e.target.src = process.env.REACT_APP_ASSET_URL + "/global/landscape_image_broken.png"
                     }}
                />
            );
        } else if (this.props.imageType === 'live_radio') {
            return (
                <img className="img-responsive center-block"
                     style={{maxHeight: '180px', maxWidth: '90%', cursor: 'pointer'}}
                     src={this.handleImageLoad(this.props.imageName, this.props.imageType, this.props.imageTemp, this.props.imageFolderTemp, this.props.imageSource, this.props.imageTmdbType)}
                     alt={this.props.imageName}
                     onClick={() => this.openLightbox(this.handleImageLoad(this.props.imageName, this.props.imageType, this.props.imageTemp, this.props.imageFolderTemp, this.props.imageSource, this.props.imageTmdbType))}
                     onError={(e) => {
                         e.target.onerror = null;
                         e.target.src = process.env.REACT_APP_ASSET_URL + "/global/landscape_image_broken.png"
                     }}
                />
            );
        } else if (this.props.imageType === 'apps_list') {
            return (
                <img className="img-responsive center-block"
                     style={{maxHeight: '180px', maxWidth: '90%', cursor: 'pointer'}}
                     src={this.handleImageLoad(this.props.imageName, this.props.imageType, this.props.imageTemp, this.props.imageFolderTemp, this.props.imageSource, this.props.imageTmdbType)}
                     alt={this.props.imageName}
                     onClick={() => this.openLightbox(this.handleImageLoad(this.props.imageName, this.props.imageType, this.props.imageTemp, this.props.imageFolderTemp, this.props.imageSource, this.props.imageTmdbType))}
                     onError={(e) => {
                         e.target.onerror = null;
                         e.target.src = process.env.REACT_APP_ASSET_URL + "/global/landscape_image_broken.png"
                     }}
                />
            );
        } else if (this.props.imageType === 'global') {
            return (
                <img className="img-responsive center-block"
                     style={{maxHeight: '180px', maxWidth: '90%', cursor: 'pointer'}}
                     src={this.handleImageLoad(this.props.imageName, this.props.imageType, this.props.imageTemp, this.props.imageFolderTemp, this.props.imageSource, this.props.imageTmdbType)}
                     alt={this.props.imageName}
                     onClick={() => this.openLightbox(this.handleImageLoad(this.props.imageName, this.props.imageType, this.props.imageTemp, this.props.imageFolderTemp, this.props.imageSource, this.props.imageTmdbType))}
                     onError={(e) => {
                         e.target.onerror = null;
                         e.target.src = process.env.REACT_APP_ASSET_URL + "/global/landscape_image_broken.png"
                     }}
                />
            );
        } else if (this.props.imageType === 'vod_cat' || this.props.imageType === 'live_match_cat' || this.props.imageType === 'apps_cat' || this.props.imageType === 'live_tv_cat' || this.props.imageType === 'live_radio_cat' || this.props.imageType === 'live_events_cat') {
            return (
                <img className="img-responsive center-block"
                     style={{maxHeight: '120px', maxWidth: '90%', cursor: 'pointer'}}
                     src={this.handleImageLoad(this.props.imageName, this.props.imageType, this.props.imageTemp, this.props.imageFolderTemp, this.props.imageSource, this.props.imageTmdbType)}
                     alt={this.props.imageName}
                     onClick={() => this.openLightbox(this.handleImageLoad(this.props.imageName, this.props.imageType, this.props.imageTemp, this.props.imageFolderTemp, this.props.imageSource, this.props.imageTmdbType))}
                     onError={(e) => {
                         e.target.onerror = null;
                         e.target.src = process.env.REACT_APP_ASSET_URL + "/global/landscape_image_broken.png"
                     }}
                />
            );
        } else if (this.props.imageType === 'properties' ) {
            return (
                <img className="img-responsive center-block"
                     style={{maxHeight: '120px', maxWidth: '50%', cursor: 'pointer'}}
                     src={this.handleImageLoad(this.props.imageName, this.props.imageType, this.props.imageTemp, this.props.imageFolderTemp, this.props.imageSource, this.props.imageTmdbType)}
                     alt={this.props.imageName}
                     onClick={() => this.openLightbox(this.handleImageLoad(this.props.imageName, this.props.imageType, this.props.imageTemp, this.props.imageFolderTemp, this.props.imageSource, this.props.imageTmdbType))}
                     onError={(e) => {
                         e.target.onerror = null;
                         e.target.src = process.env.REACT_APP_ASSET_URL + "/global/landscape_image_broken.png"
                     }}
                />
            );
        } else {
            return (
                <img className="img-responsive center-block"
                     style={{maxHeight: '400px', maxWidth: '100%', cursor: 'pointer'}}
                     src={this.handleImageLoad(this.props.imageName, this.props.imageType, this.props.imageTemp, this.props.imageFolderTemp, this.props.imageSource, this.props.imageTmdbType)}
                     alt={this.props.imageName}
                     onClick={() => this.openLightbox(this.handleImageLoad(this.props.imageName, this.props.imageType, this.props.imageTemp, this.props.imageFolderTemp, this.props.imageSource, this.props.imageTmdbType))}
                     onError={(e) => {
                         e.target.onerror = null;
                         e.target.src = process.env.REACT_APP_ASSET_URL + "/global/landscape_image_broken.png"
                     }}
                />
            );
        }

    }


    closeLightbox() {
        this.setState({preview: false, previewUrl: null})
    }

    openLightbox(url) {
        //console.log(url)
        if (!isMobile) {
            this.setState({preview: true, previewUrl: url})
        }
    }

    renderImagePreview = () => {

        if (this.state.preview) {
            return (
                <Lightbox
                    medium={this.state.previewUrl}
                    alt={this.state.previewUrl}
                    onClose={this.closeLightbox}
                />
            );
        }

    }

    render() {
        return (
            <div className="ImageUpload">
                {this.renderImagePreview()}
                <FormGroup>
                    {this.renderImage()}
                </FormGroup>

                <FormGroup>
                    <FilePond
                        allowMultiple={false}
                        maxFiles={1}
                        allowImagePreview={false}
                        allowFileRename={true}
                        acceptedFileTypes={[
                            'image/*'
                        ]}
                        server="/api/helpers/api/"

                        onprocessfile={(error, file) => {
                            this.handleImageSelect(file.serverId, file.filename, file);
                        }}
                    >
                    </FilePond>

                    <Button simple icon onClick={this.handleImageDelete.bind(this)}>
                        <i className="fa fa-trash"></i>
                    </Button>


                </FormGroup>

            </div>
        );
    }
}

export default ImageUpload;