import React, {Component} from "react";
import axios from "axios/index";
import DualListBox from 'react-dual-listbox';
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import {BeatLoader} from "react-spinners";
//import {css} from "emotion";
import { css } from "@emotion/core";
//https://github.com/jakezatecky/react-dual-listbox
//https://github.com/jakezatecky/react-dual-listbox/issues/41

const override = css`
    position: absolute;
    left: 47%;
    top: 30%;
    display: block;
    z-index: 1000;
    `;


class DualListBoxPackageSvodMovies extends Component {

    constructor(props) {
        super(props);

        this.handleMovieSelect = this.handleMovieSelect.bind(this);

        this.state = {
            loading: false,

            availableSvodContent: [],
            selectedArray: []
        };
        this.onChange = this.onChange.bind(this);

    }

    handleMovieSelect(selected) {
        this.props.handlerFromParent(selected);
    }

    onChange(selected) {
        this.setState({selectedArray: selected});
        this.handleMovieSelect(selected)
    }

    componentDidMount() {
        this.loadAvailableMoviesFromMiddleware();
    }

    loadAvailableMoviesFromMiddleware() {

        this.setState({ loading:true});

        const url = process.env.REACT_APP_API_URL + "/Svod/List";

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {'X-Id-Token': token}
        };


        axios
            .get(url, axiosConfig)
            .then(res => {

                let temp = res.data;

                let availableSvodContent = [];
                let obj = {};

                for (let i = 0; i < temp.length; i++) {
                    //console.log(temp[i].value.toString())

                    obj = {
                        value: temp[i].vod_id.toString(),
                        label: temp[i].vod_name_long

                    }
                    availableSvodContent.push(obj)
                }

                this.setState({availableSvodContent, loading:false});
            })
            .catch(error => {
                console.log(error);
            });

    }

    render() {
        return (
            <div>

                <div className="sweet-loader">
                    <BeatLoader
                        css={override}
                        size={5}
                        color={'#2c67a4'}
                        loading={this.state.loading}
                        //loading={true}
                    />
                </div>

            <DualListBox
                options={this.state.availableSvodContent}
                canFilter
                availableLabel={"Available"}
                selectedLabel={"Selected"}
                showHeaderLabels
                simpleValue={true}
                selected={this.props.moviesInPackage}
                onChange={this.onChange}
            />
            </div>
        );
    }
}


function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedDualListBoxPackageSvodMovies = connect(mapStateToProps)(DualListBoxPackageSvodMovies);
const connectedDualListBoxPackageSvodMoviesTrans = withNamespaces('common')(connectedDualListBoxPackageSvodMovies);
export {connectedDualListBoxPackageSvodMoviesTrans as DualListBoxPackageSvodMovies};
