const tvSeries = [
    {
        "id": "vod_series_id",
        "name": "vod_series_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_series_position",
        "name": "vod_series_position",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_series_status",
        "name": "vod_series_status",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_series_name_short",
        "name": "vod_series_name_short",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_series_name_long",
        "name": "vod_series_name_long",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_series_description",
        "name": "vod_series_description",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_series_image",
        "name": "vod_series_image",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_series_image_wide",
        "name": "vod_series_image_wide",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_series_image_backdrop",
        "name": "vod_series_image_backdrop",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_series_genre_id",
        "name": "vod_series_genre_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_series_genre_names",
        "name": "vod_series_genre_names",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_series_cat_id",
        "name": "vod_series_cat_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_series_vut_category_display",
        "name": "vod_series_vut_category_display",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_series_rating",
        "name": "vod_series_rating",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_series_number_of_seasons",
        "name": "vod_series_number_of_seasons",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_series_created_by",
        "name": "vod_series_created_by",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_series_first_air_date",
        "name": "vod_series_first_air_date",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_series_tmdb_id",
        "name": "vod_series_tmdb_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_series_in_production",
        "name": "vod_series_in_production",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_series_production_status",
        "name": "vod_series_production_status",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_series_production_countries",
        "name": "vod_series_production_countries",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_series_production_companies",
        "name": "vod_series_production_companies",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_series_date_added",
        "name": "vod_series_date_added",
        "defaultValue": true,
        "disabled": true
    }
]

const vodCategories = [
    {
        "id": "AssetCount",
        "name": "AssetCount",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "VodCount",
        "name": "VodCount",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "SeriesCount",
        "name": "SeriesCount",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_cat_id",
        "name": "vod_cat_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_cat_position",
        "name": "vod_cat_position",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_cat_status",
        "name": "vod_cat_status",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_cat_type",
        "name": "vod_cat_type",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_cat_system_name",
        "name": "vod_cat_system_name",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_cat_image",
        "name": "vod_cat_image",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_cat_name_short",
        "name": "vod_cat_name_short",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_cat_name_long",
        "name": "vod_cat_name_long",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_cat_description",
        "name": "vod_cat_description",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_cat_date_added",
        "name": "vod_cat_date_added",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_cat_date_updated",
        "name": "vod_cat_date_updated",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_cat_date_deleted",
        "name": "vod_cat_date_deleted",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_cat_user_added",
        "name": "vod_cat_user_added",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_cat_user_updated",
        "name": "vod_cat_user_updated",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_cat_user_deleted",
        "name": "vod_cat_user_deleted",
        "defaultValue": true,
        "disabled": true
    }
];

const deviceCheckBox = [
    {
        "id": "ID",
        "name": "ID",
        "defaultValue": true
    },
    {
        "id": "Enabled",
        "name": "Enabled",
        "defaultValue": true
    },
    {
        "id": "MAC_ADDRESS",
        "name": "MAC_ADDRESS",
        "defaultValue": true
    },
    {
        "id": "decoder_wifi_mac",
        "name": "decoder_wifi_mac",
        "defaultValue": false
    },
    {
        "id": "SERIAL",
        "name": "SERIAL",
        "defaultValue": true
    },
    {
        "id": "MODEL_ID",
        "name": "MODEL_ID",
        "defaultValue": true
    },
    {
        "id": "transaction_pin",
        "name": "transaction_pin",
        "defaultValue": true
    },
    {
        "id": "decoder_parentalcode",
        "name": "decoder_parentalcode",
        "defaultValue": false
    },
    {
        "id": "decoder_customer_id",
        "name": "decoder_customer_id",
        "defaultValue": true
    },
    {
        "id": "decoder_block_ppv",
        "name": "decoder_block_ppv",
        "defaultValue": false
    },
    {
        "id": "decoder_block_tvod",
        "name": "decoder_block_tvod",
        "defaultValue": false
    },
    {
        "id": "decoder_date_connected",
        "name": "decoder_date_connected",
        "defaultValue": true
    },
    {
        "id": "LAST_DATE",
        "name": "LAST_DATE",
        "defaultValue": true
    },
    {
        "id": "LAST_IP",
        "name": "LAST_IP",
        "defaultValue": true
    },
    {
        "id": "decoder_vmx_smsDeviceId",
        "name": "decoder_vmx_smsDeviceId",
        "defaultValue": false
    },
    {
        "id": "decoder_vmx_networkDeviceId",
        "name": "decoder_vmx_networkDeviceId",
        "defaultValue": false
    },
    {
        "id": "decoder_connection_type",
        "name": "decoder_connection_type",
        "defaultValue": false
    },
    {
        "id": "decoder_signal_level",
        "name": "decoder_signal_level",
        "defaultValue": false
    },
    {
        "id": "decoder_firmware_version",
        "name": "decoder_firmware_version",
        "defaultValue": false
    },
    {
        "id": "decoder_ui_version",
        "name": "decoder_ui_version",
        "defaultValue": false
    },
    {
        "id": "decoder_ssi_version",
        "name": "decoder_ssi_version",
        "defaultValue": false
    },
    {
        "id": "decoder_signout_status",
        "name": "decoder_signout_status",
        "defaultValue": false
    }
];

const customerCheckBox = [
    {
        "id": "user_id",
        "name": "user_id",
        "defaultValue": true
    },
    {
        "id": "user_status",
        "name": "user_status",
        "defaultValue": true
    },
    {
        "id": "user_test_account",
        "name": "user_test_account",
        "defaultValue": true
    },
    {
        "id": "user_name",
        "name": "user_name",
        "defaultValue": true
    },
    {
        "id": "user_lastname",
        "name": "user_lastname",
        "defaultValue": true
    },
    {
        "id": "user_username",
        "name": "user_username",
        "defaultValue": true
    },
    {
        "id": "user_email",
        "name": "user_email",
        "defaultValue": true
    },
    {
        "id": "user_register_date",
        "name": "user_register_date",
        "defaultValue": true
    },
    {
        "id": "user_user_mobile",
        "name": "user_user_mobile",
        "defaultValue": false
    },
    {
        "id": "user_user_tel",
        "name": "user_user_tel",
        "defaultValue": false
    },
    {
        "id": "user_user_state",
        "name": "user_user_state",
        "defaultValue": false
    },
    {
        "id": "user_city",
        "name": "user_city",
        "defaultValue": false
    },
    {
        "id": "user_country",
        "name": "user_country",
        "defaultValue": false
    },
    {
        "id": "user_user_address",
        "name": "user_user_address",
        "defaultValue": false
    },
    {
        "id": "user_address_number",
        "name": "user_address_number",
        "defaultValue": false
    },
    {
        "id": "user_user_zipcode",
        "name": "user_user_zipcode",
        "defaultValue": false
    },
    {
        "id": "user_parentid",
        "name": "user_parentid",
        "defaultValue": true
    },
    {
        "id": "user_billing_id",
        "name": "user_billing_id",
        "defaultValue": true
    },
    {
        "id": "user_region_code",
        "name": "user_region_code",
        "defaultValue": true
    },
    {
        "id": "user_block_tvod",
        "name": "user_block_tvod",
        "defaultValue": true
    },
    {
        "id": "user_block_ppv",
        "name": "user_block_ppv",
        "defaultValue": true
    },
    {
        "id": "user_npvr_status",
        "name": "user_npvr_status",
        "defaultValue": true
    },
    {
        "id": "user_catchup_status",
        "name": "user_catchup_status",
        "defaultValue": true
    },
    {
        "id": "user_restart_status",
        "name": "user_restart_status",
        "defaultValue": true
    },
    {
        "id": "user_dvr_usage",
        "name": "user_dvr_usage",
        "defaultValue": false
    },
    {
        "id": "user_mobile_limit",
        "name": "user_mobile_limit",
        "defaultValue": false
    },
    {
        "id": "user_date_of_birth",
        "name": "user_date_of_birth",
        "defaultValue": false
    },
    {
        "id": "user_genre",
        "name": "user_genre",
        "defaultValue": false
    },
    {
        "id": "user_lat",
        "name": "user_lat",
        "defaultValue": false
    },
    {
        "id": "user_long",
        "name": "user_long",
        "defaultValue": false
    },
    {
        "id": "user_last_updated_time",
        "name": "user_last_updated_time",
        "defaultValue": false
    },
    {
        "id": "user_last_connected_ip",
        "name": "user_last_connected_ip",
        "defaultValue": false
    },
    {
        "id": "user_dealer_id",
        "name": "user_dealer_id",
        "defaultValue": false
    },
    {
        "id": "user_group_ids",
        "name": "user_group_ids",
        "defaultValue": false
    },
    {
        "id": "user_group_tags",
        "name": "user_group_tags",
        "defaultValue": false
    }
];

const channelsCheckBox = [
    {
        "id": "channel_id",
        "name": "channel_id",
        "defaultValue": true
    },
    {
        "id": "channel_status",
        "name": "channel_status",
        "defaultValue": true
    },
    {
        "id": "channel_position",
        "name": "channel_position",
        "defaultValue": true
    },
    {
        "id": "channel_bkp_position",
        "name": "channel_bkp_position",
        "defaultValue": true
    },
    {
        "id": "channel_name_short",
        "name": "channel_name_short",
        "defaultValue": true
    },
    {
        "id": "channel_name_long",
        "name": "channel_name_long",
        "defaultValue": true
    },
    {
        "id": "channel_description",
        "name": "channel_description",
        "defaultValue": false
    },
    {
        "id": "channel_cat_id",
        "name": "channel_cat_id",
        "defaultValue": false
    },
    {
        "id": "channel_genre_id",
        "name": "channel_genre_id",
        "defaultValue": false
    },
    {
        "id": "channel_genre_names",
        "name": "channel_genre_names",
        "defaultValue": false
    },
    {
        "id": "channel_blackout_countries",
        "name": "channel_blackout_countries",
        "defaultValue": false
    },
    {
        "id": "channel_ott_url",
        "name": "channel_ott_url",
        "defaultValue": true
    },
    {
        "id": "channel_iptv_url",
        "name": "channel_iptv_url",
        "defaultValue": true
    },
    {
        "id": "channel_catchup_url",
        "name": "channel_catchup_url",
        "defaultValue": true
    },
    {
        "id": "channel_start_over_url",
        "name": "channel_start_over_url",
        "defaultValue": true
    },
    {
        "id": "channel_blackout_url",
        "name": "channel_blackout_url",
        "defaultValue": false
    },
    {
        "id": "channel_image",
        "name": "channel_image",
        "defaultValue": true
    },
    {
        "id": "channel_ref_id",
        "name": "channel_ref_id",
        "defaultValue": false
    },
    {
        "id": "channel_epg_id",
        "name": "channel_epg_id",
        "defaultValue": false
    },
    {
        "id": "channel_mode_id",
        "name": "channel_mode_id",
        "defaultValue": false
    },
    {
        "id": "channel_resolution_id",
        "name": "channel_resolution_id",
        "defaultValue": false
    },
    {
        "id": "channel_image_wide",
        "name": "channel_image_wide",
        "defaultValue": false
    },
    {
        "id": "channel_AudioPID",
        "name": "channel_AudioPID",
        "defaultValue": false
    },
    {
        "id": "channel_VideoPID",
        "name": "channel_VideoPID",
        "defaultValue": false
    },
    {
        "id": "channel_timeshift_offset",
        "name": "channel_timeshift_offset",
        "defaultValue": false
    },
    {
        "id": "channel_country_lock",
        "name": "channel_country_lock",
        "defaultValue": false
    },
    {
        "id": "channel_website",
        "name": "channel_website",
        "defaultValue": false
    },
    {
        "id": "channel_pre_roll",
        "name": "channel_pre_roll",
        "defaultValue": false
    },
    {
        "id": "channel_mid_roll",
        "name": "channel_mid_roll",
        "defaultValue": false
    },
    {
        "id": "channel_post_roll",
        "name": "channel post roll",
        "defaultValue": false
    },
    {
        "id": "channel_npvr_type",
        "name": "channel_npvr_type",
        "defaultValue": false
    }


];

const tvodCheckBox = [
    {
        "id": "vod_id",
        "name": "vod_id",
        "defaultValue": true
    },
    {
        "id": "vod_position",
        "name": "vod_position",
        "defaultValue": true
    },
    {
        "id": "vod_status",
        "name": "vod_status",
        "defaultValue": true
    },
    {
        "id": "vod_name_short",
        "name": "vod_name_short",
        "defaultValue": false
    },
    {
        "id": "vod_name_long",
        "name": "vod_name_long",
        "defaultValue": true
    },
    {
        "id": "vod_description",
        "name": "vod_description",
        "defaultValue": true
    },
    {
        "id": "vod_duration",
        "name": "vod_duration",
        "defaultValue": true
    },
    {
        "id": "vod_country",
        "name": "vod_country",
        "defaultValue": false
    },
    {
        "id": "vod_year",
        "name": "vod_year",
        "defaultValue": true
    },
    {
        "id": "vod_release_date",
        "name": "vod_release_date",
        "defaultValue": false
    },
    {
        "id": "vod_producer",
        "name": "vod_producer",
        "defaultValue": false
    },
    {
        "id": "vod_actors",
        "name": "vod_actors",
        "defaultValue": true
    },
    {
        "id": "vod_director",
        "name": "vod_director",
        "defaultValue": true
    },
    {
        "id": "vod_cat_id",
        "name": "vod_cat_id",
        "defaultValue": true
    },
    {
        "id": "vod_image",
        "name": "vod_image",
        "defaultValue": false
    },
    {
        "id": "vod_image_wide",
        "name": "vod_image_wide",
        "defaultValue": false
    },
    {
        "id": "vod_image_backdrop",
        "name": "vod_image_backdrop",
        "defaultValue": false
    },
    {
        "id": "vod_rating",
        "name": "vod_rating",
        "defaultValue": false
    },
    {
        "id": "vod_iptv_url",
        "name": "vod_iptv_url",
        "defaultValue": false
    },
    {
        "id": "vod_ott_url",
        "name": "vod_ott_url",
        "defaultValue": false
    },
    {
        "id": "vod_genre_names",
        "name": "vod_genre_names",
        "defaultValue": false
    },
    {
        "id": "vod_country_id",
        "name": "vod_country_id",
        "defaultValue": false
    },
    {
        "id": "vod_lang_id",
        "name": "vod_lang_id",
        "defaultValue": false
    },
    {
        "id": "vod_trailer_iptv_url",
        "name": "vod_trailer_iptv_url",
        "defaultValue": false
    },
    {
        "id": "vod_trailer_ott_url",
        "name": "vod_trailer_ott_url",
        "defaultValue": false
    },
    {
        "id": "vod_encrypted",
        "name": "vod_encrypted",
        "defaultValue": false
    },
    {
        "id": "vod_parentalLock",
        "name": "vod_parentalLock",
        "defaultValue": false
    },
    {
        "id": "vod_content_rating",
        "name": "vod_content_rating",
        "defaultValue": false
    },
    {
        "id": "vod_pricing_group",
        "name": "vod_pricing_group",
        "defaultValue": false
    },
    {
        "id": "vod_price",
        "name": "vod_price",
        "defaultValue": false
    },
    {
        "id": "vod_tmdb_id",
        "name": "vod_tmdb_id",
        "defaultValue": false
    },
    {
        "id": "vod_vut_id",
        "name": "vod_vut_id",
        "defaultValue": false
    },
    {
        "id": "vod_vut_type",
        "name": "vod_vut_type",
        "defaultValue": false
    },
    {
        "id": "vod_vut_licensing_window_start",
        "name": "vod_vut_licensing_window_start",
        "defaultValue": false
    },
    {
        "id": "vod_vut_licensing_window_end",
        "name": "vod_vut_licensing_window_end",
        "defaultValue": false
    },
    {
        "id": "vod_vut_billing_id",
        "name": "vod_vut_billing_id",
        "defaultValue": false
    },
    {
        "id": "vod_vut_publish_id",
        "name": "vod_vut_publish_id",
        "defaultValue": false
    },
    {
        "id": "vod_vut_category_display",
        "name": "vod_vut_category_display",
        "defaultValue": false
    },
    {
        "id": "vod_vut_suggested_price",
        "name": "vod_vut_suggested_price",
        "defaultValue": false
    },
    {
        "id": "vod_vut_id_update",
        "name": "vod_vut_id_update",
        "defaultValue": false
    },
    {
        "id": "vod_vut_update_date",
        "name": "vod_vut_update_date",
        "defaultValue": false
    },
    {
        "id": "vod_purchase_counter",
        "name": "vod_purchase_counter",
        "defaultValue": false
    },
    {
        "id": "vod_physical_path",
        "name": "vod_physical_path",
        "defaultValue": false
    },
    {
        "id": "vod_pre_roll",
        "name": "vod_pre_roll",
        "defaultValue": false
    },
    {
        "id": "vod_mid_roll",
        "name": "vod_mid_roll",
        "defaultValue": false
    },
    {
        "id": "vod_date_deleted",
        "name": "vod_date_deleted",
        "defaultValue": false
    },
    {
        "id": "vod_user_deleted",
        "name": "vod_user_deleted",
        "defaultValue": false
    },
    {
        "id": "vod_date_added",
        "name": "vod_date_added",
        "defaultValue": false
    },
    {
        "id": "vod_user_added",
        "name": "vod_user_added",
        "defaultValue": false
    },
    {
        "id": "vod_user_updated",
        "name": "vod_user_updated",
        "defaultValue": false
    },
    {
        "id": "vod_date_updated",
        "name": "vod_date_updated",
        "defaultValue": false
    }
];

const avodCheckBox = [
    {
        "id": "vod_id",
        "name": "vod_id",
        "defaultValue": true
    },
    {
        "id": "vod_position",
        "name": "vod_position",
        "defaultValue": true
    },
    {
        "id": "vod_status",
        "name": "vod_status",
        "defaultValue": true
    },
    {
        "id": "vod_name_short",
        "name": "vod_name_short",
        "defaultValue": false
    },
    {
        "id": "vod_name_long",
        "name": "vod_name_long",
        "defaultValue": true
    },
    {
        "id": "vod_description",
        "name": "vod_description",
        "defaultValue": true
    },
    {
        "id": "vod_duration",
        "name": "vod_duration",
        "defaultValue": true
    },
    {
        "id": "vod_country",
        "name": "vod_country",
        "defaultValue": false
    },
    {
        "id": "vod_year",
        "name": "vod_year",
        "defaultValue": true
    },
    {
        "id": "vod_release_date",
        "name": "vod_release_date",
        "defaultValue": false
    },
    {
        "id": "vod_producer",
        "name": "vod_producer",
        "defaultValue": false
    },
    {
        "id": "vod_actors",
        "name": "vod_actors",
        "defaultValue": true
    },
    {
        "id": "vod_director",
        "name": "vod_director",
        "defaultValue": true
    },
    {
        "id": "vod_cat_id",
        "name": "vod_cat_id",
        "defaultValue": true
    },
    {
        "id": "vod_image",
        "name": "vod_image",
        "defaultValue": false
    },
    {
        "id": "vod_image_wide",
        "name": "vod_image_wide",
        "defaultValue": false
    },
    {
        "id": "vod_image_backdrop",
        "name": "vod_image_backdrop",
        "defaultValue": false
    },
    {
        "id": "vod_rating",
        "name": "vod_rating",
        "defaultValue": false
    },
    {
        "id": "vod_seriesid",
        "name": "vod_seriesid",
        "defaultValue": false
    },
    {
        "id": "vod_seasonid",
        "name": "vod_seasonid",
        "defaultValue": false
    },
    {
        "id": "vod_iptv_url",
        "name": "vod_iptv_url",
        "defaultValue": false
    },
    {
        "id": "vod_ott_url",
        "name": "vod_ott_url",
        "defaultValue": false
    },
    {
        "id": "vod_genre_id",
        "name": "vod_genre_id",
        "defaultValue": false
    },
    {
        "id": "vod_genre_names",
        "name": "vod_genre_names",
        "defaultValue": false
    },
    {
        "id": "vod_country_id",
        "name": "vod_country_id",
        "defaultValue": false
    },
    {
        "id": "vod_lang_id",
        "name": "vod_lang_id",
        "defaultValue": false
    },
    {
        "id": "vod_trailer_iptv_url",
        "name": "vod_trailer_iptv_url",
        "defaultValue": false
    },
    {
        "id": "vod_trailer_ott_url",
        "name": "vod_trailer_ott_url",
        "defaultValue": false
    },
    {
        "id": "vod_encrypted",
        "name": "vod_encrypted",
        "defaultValue": false
    },
    {
        "id": "vod_parentalLock",
        "name": "vod_parentalLock",
        "defaultValue": false
    },
    {
        "id": "vod_content_rating",
        "name": "vod_content_rating",
        "defaultValue": false
    },
    {
        "id": "vod_pricing_group",
        "name": "vod_pricing_group",
        "defaultValue": false
    },
    {
        "id": "vod_user_id",
        "name": "vod_user_id",
        "defaultValue": false
    },
    {
        "id": "vod_price",
        "name": "vod_price",
        "defaultValue": false
    },
    {
        "id": "vod_imdbid",
        "name": "vod_imdbid",
        "defaultValue": false
    },
    {
        "id": "vod_tmdb_id",
        "name": "vod_tmdb_id",
        "defaultValue": false
    },
    {
        "id": "vod_vut_id",
        "name": "vod_vut_id",
        "defaultValue": false
    },
    {
        "id": "vod_vut_type",
        "name": "vod_vut_type",
        "defaultValue": false
    },
    {
        "id": "vod_vut_licensing_window_start",
        "name": "vod_vut_licensing_window_start",
        "defaultValue": false
    },
    {
        "id": "vod_vut_licensing_window_end",
        "name": "vod_vut_licensing_window_end",
        "defaultValue": false
    },
    {
        "id": "vod_vut_billing_id",
        "name": "vod_vut_billing_id",
        "defaultValue": false
    },
    {
        "id": "vod_vut_publish_id",
        "name": "vod_vut_publish_id",
        "defaultValue": false
    },
    {
        "id": "vod_vut_category_display",
        "name": "vod_vut_category_display",
        "defaultValue": false
    },
    {
        "id": "vod_vut_suggested_price",
        "name": "vod_vut_suggested_price",
        "defaultValue": false
    },
    {
        "id": "vod_vut_id_update",
        "name": "vod_vut_id_update",
        "defaultValue": false
    },
    {
        "id": "vod_vut_update_date",
        "name": "vod_vut_update_date",
        "defaultValue": false
    },
    {
        "id": "vod_purchase_counter",
        "name": "vod_purchase_counter",
        "defaultValue": false
    },
    {
        "id": "vod_physical_path",
        "name": "vod_physical_path",
        "defaultValue": false
    },
    {
        "id": "vod_pre_roll",
        "name": "vod_pre_roll",
        "defaultValue": false
    },
    {
        "id": "vod_mid_roll",
        "name": "vod_mid_roll",
        "defaultValue": false
    },
    {
        "id": "vod_date_deleted",
        "name": "vod_date_deleted",
        "defaultValue": false
    },
    {
        "id": "vod_user_deleted",
        "name": "vod_user_deleted",
        "defaultValue": false
    },
    {
        "id": "vod_date_added",
        "name": "vod_date_added",
        "defaultValue": false
    },
    {
        "id": "vod_user_added",
        "name": "vod_user_added",
        "defaultValue": false
    },
    {
        "id": "vod_user_updated",
        "name": "vod_user_updated",
        "defaultValue": false
    },
    {
        "id": "vod_date_updated",
        "name": "vod_date_updated",
        "defaultValue": false
    }
];

const svodCheckBox = [
    {
        "id": "vod_id",
        "name": "vod_id",
        "defaultValue": true
    },
    {
        "id": "vod_position",
        "name": "vod_position",
        "defaultValue": true
    },
    {
        "id": "vod_status",
        "name": "vod_status",
        "defaultValue": true
    },
    {
        "id": "vod_name_short",
        "name": "vod_name_short",
        "defaultValue": false
    },
    {
        "id": "vod_name_long",
        "name": "vod_name_long",
        "defaultValue": true
    },
    {
        "id": "vod_description",
        "name": "vod_description",
        "defaultValue": true
    },
    {
        "id": "vod_duration",
        "name": "vod_duration",
        "defaultValue": true
    },
    {
        "id": "vod_country",
        "name": "vod_country",
        "defaultValue": false
    },
    {
        "id": "vod_year",
        "name": "vod_year",
        "defaultValue": true
    },
    {
        "id": "vod_release_date",
        "name": "vod_release_date",
        "defaultValue": false
    },
    {
        "id": "vod_producer",
        "name": "vod_producer",
        "defaultValue": false
    },
    {
        "id": "vod_actors",
        "name": "vod_actors",
        "defaultValue": true
    },
    {
        "id": "vod_director",
        "name": "vod_director",
        "defaultValue": true
    },
    {
        "id": "vod_cat_id",
        "name": "vod_cat_id",
        "defaultValue": true
    },
    {
        "id": "vod_image",
        "name": "vod_image",
        "defaultValue": false
    },
    {
        "id": "vod_image_wide",
        "name": "vod_image_wide",
        "defaultValue": false
    },
    {
        "id": "vod_image_backdrop",
        "name": "vod_image_backdrop",
        "defaultValue": false
    },
    {
        "id": "vod_rating",
        "name": "vod_rating",
        "defaultValue": false
    },
    {
        "id": "vod_seriesid",
        "name": "vod_seriesid",
        "defaultValue": false
    },
    {
        "id": "vod_seasonid",
        "name": "vod_seasonid",
        "defaultValue": false
    },
    {
        "id": "vod_iptv_url",
        "name": "vod_iptv_url",
        "defaultValue": false
    },
    {
        "id": "vod_ott_url",
        "name": "vod_ott_url",
        "defaultValue": false
    },
    {
        "id": "vod_genre_id",
        "name": "vod_genre_id",
        "defaultValue": false
    },
    {
        "id": "vod_genre_names",
        "name": "vod_genre_names",
        "defaultValue": false
    },
    {
        "id": "vod_country_id",
        "name": "vod_country_id",
        "defaultValue": false
    },
    {
        "id": "vod_lang_id",
        "name": "vod_lang_id",
        "defaultValue": false
    },
    {
        "id": "vod_trailer_iptv_url",
        "name": "vod_trailer_iptv_url",
        "defaultValue": false
    },
    {
        "id": "vod_trailer_ott_url",
        "name": "vod_trailer_ott_url",
        "defaultValue": false
    },
    {
        "id": "vod_encrypted",
        "name": "vod_encrypted",
        "defaultValue": false
    },
    {
        "id": "vod_parentalLock",
        "name": "vod_parentalLock",
        "defaultValue": false
    },
    {
        "id": "vod_content_rating",
        "name": "vod_content_rating",
        "defaultValue": false
    },
    {
        "id": "vod_pricing_group",
        "name": "vod_pricing_group",
        "defaultValue": false
    },
    {
        "id": "vod_user_id",
        "name": "vod_user_id",
        "defaultValue": false
    },
    {
        "id": "vod_price",
        "name": "vod_price",
        "defaultValue": false
    },
    {
        "id": "vod_imdbid",
        "name": "vod_imdbid",
        "defaultValue": false
    },
    {
        "id": "vod_tmdb_id",
        "name": "vod_tmdb_id",
        "defaultValue": false
    },
    {
        "id": "vod_vut_id",
        "name": "vod_vut_id",
        "defaultValue": false
    },
    {
        "id": "vod_vut_type",
        "name": "vod_vut_type",
        "defaultValue": false
    },
    {
        "id": "vod_vut_licensing_window_start",
        "name": "vod_vut_licensing_window_start",
        "defaultValue": false
    },
    {
        "id": "vod_vut_licensing_window_end",
        "name": "vod_vut_licensing_window_end",
        "defaultValue": false
    },
    {
        "id": "vod_vut_billing_id",
        "name": "vod_vut_billing_id",
        "defaultValue": false
    },
    {
        "id": "vod_vut_publish_id",
        "name": "vod_vut_publish_id",
        "defaultValue": false
    },
    {
        "id": "vod_vut_category_display",
        "name": "vod_vut_category_display",
        "defaultValue": false
    },
    {
        "id": "vod_vut_suggested_price",
        "name": "vod_vut_suggested_price",
        "defaultValue": false
    },
    {
        "id": "vod_vut_id_update",
        "name": "vod_vut_id_update",
        "defaultValue": false
    },
    {
        "id": "vod_vut_update_date",
        "name": "vod_vut_update_date",
        "defaultValue": false
    },
    {
        "id": "vod_purchase_counter",
        "name": "vod_purchase_counter",
        "defaultValue": false
    },
    {
        "id": "vod_physical_path",
        "name": "vod_physical_path",
        "defaultValue": false
    },
    {
        "id": "vod_pre_roll",
        "name": "vod_pre_roll",
        "defaultValue": false
    },
    {
        "id": "vod_mid_roll",
        "name": "vod_mid_roll",
        "defaultValue": false
    },
    {
        "id": "vod_date_deleted",
        "name": "vod_date_deleted",
        "defaultValue": false
    },
    {
        "id": "vod_user_deleted",
        "name": "vod_user_deleted",
        "defaultValue": false
    },
    {
        "id": "vod_date_added",
        "name": "vod_date_added",
        "defaultValue": false
    },
    {
        "id": "vod_user_added",
        "name": "vod_user_added",
        "defaultValue": false
    },
    {
        "id": "vod_user_updated",
        "name": "vod_user_updated",
        "defaultValue": false
    },
    {
        "id": "vod_date_updated",
        "name": "vod_date_updated",
        "defaultValue": false
    }
];

const speedTestCheckBox = [
    {
        "id": "id",
        "name": "id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "customer_id",
        "name": "customer_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "customer_billing_id",
        "name": "customer_billing_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "mac_address",
        "name": "mac_address",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "latency",
        "name": "latency",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "download_speed",
        "name": "download_speed",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "test_datetime",
        "name": "test_datetime",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "testcase",
        "name": "testcase",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "DGping_min",
        "name": "DGping_min",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "DGping_max",
        "name": "DGping_max",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "DFpacket_loss_percentage",
        "name": "DFpacket_loss_percentage",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "serverping_min",
        "name": "serverping_min",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "serverping_max",
        "name": "serverping_max",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "serverping_latency",
        "name": "serverping_latency",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "server_packet_loss_percentage",
        "name": "server_packet_loss_percentage",
        "defaultValue": true,
        "disabled": true
    }

];

const customerVodTicketsCheckBox = [
    {
        "id": "vod_ticket_id",
        "name": "vod_ticket_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_movie_id",
        "name": "vod_movie_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_movie_name",
        "name": "vod_movie_name",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_ref_id",
        "name": "vod_ref_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "customer_id",
        "name": "customer_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "purchase_date",
        "name": "purchase_date",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "start_date",
        "name": "start_date",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "end_date",
        "name": "end_date",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "resume_position",
        "name": "resume_position",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "played",
        "name": "played",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "last_access",
        "name": "last_access",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "price",
        "name": "price",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "test",
        "name": "test",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_category",
        "name": "vod_category",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "vod_category_id",
        "name": "vod_category_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "device_mac",
        "name": "device_mac",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "adjusted_price",
        "name": "adjusted_price",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "adjusted_user_id",
        "name": "adjusted_user_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "adjusted_date",
        "name": "adjusted_date",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "adjusted_reason",
        "name": "adjusted_reason",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "PurchaseStatus",
        "name": "PurchaseStatus",
        "defaultValue": true,
        "disabled": true
    }

];

const customerPpvTicketsCheckBox = [
    {
        "id": "ppv_ticket_id",
        "name": "ppv_ticket_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "ppv_channel_id",
        "name": "ppv_channel_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "ppv_channel_name",
        "name": "ppv_channel_name",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "ppv_event_id",
        "name": "ppv_event_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "ppv_event_name",
        "name": "ppv_event_name",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "ppv_event_type",
        "name": "ppv_event_type",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "ppv_ref_id",
        "name": "ppv_ref_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "customer_id",
        "name": "customer_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "purchase_date",
        "name": "purchase_date",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "start_date",
        "name": "start_date",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "end_date",
        "name": "end_date",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "last_access",
        "name": "last_access",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "price",
        "name": "price",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "adjusted_price",
        "name": "adjusted_price",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "adjusted_user_id",
        "name": "adjusted_user_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "adjusted_date",
        "name": "adjusted_date",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "adjusted_reason",
        "name": "adjusted_reason",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "PurchaseStatus",
        "name": "PurchaseStatus",
        "defaultValue": true,
        "disabled": true
    }

];

//Type1	user_id	user_name	user_status	user_lastname	user_username	user_email	start_date	end_date	user_dealer_id	id	Status1	PackageID	PackageName	PackageStatus	DaysToExpire
const customersPackageStatus = [
    {
        "id": "Type1",
        "name": "PackageType",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "user_id",
        "name": "UserID",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "user_name",
        "name": "user_name",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "user_status",
        "name": "user_status",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "user_lastname",
        "name": "user_lastname",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "user_username",
        "name": "user_username",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "user_email",
        "name": "user_email",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "start_date",
        "name": "start_date",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "end_date",
        "name": "end_date",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "user_dealer_id",
        "name": "user_dealer_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "PackageTransactionID",
        "name": "PackageTransactionID",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "Status1",
        "name": "Status1",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "PackageID",
        "name": "PackageID",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "PackageName",
        "name": "PackageName",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "PackageStatus",
        "name": "PackageStatus",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "DaysToExpire",
        "name": "DaysToExpire",
        "defaultValue": true,
        "disabled": true
    }

];

const applicationsCheckBox = [
    {
        "id": "apps_id",
        "name": "apps_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "apps_status",
        "name": "apps_status",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "apps_position",
        "name": "apps_position",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "apps_name_short",
        "name": "apps_name_short",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "apps_name_long",
        "name": "apps_name_long",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "apps_description",
        "name": "apps_description",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "apps_cat_id",
        "name": "apps_cat_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "apps_url",
        "name": "apps_url",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "apps_url_android",
        "name": "apps_url_android",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "apps_url_ios",
        "name": "apps_url_ios",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "apps_gpgroup_id",
        "name": "apps_gpgroup_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "apps_ref_id",
        "name": "apps_ref_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "apps_image",
        "name": "apps_image",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "apps_image_wide",
        "name": "apps_image_wide",
        "defaultValue": true,
        "disabled": true
    },
    // {
    //     "id": "apps_hits",
    //     "name": "apps_hits",
    //     "defaultValue": true,
    //     "disabled": true
    // },
    // {
    //     "id": "apps_country_lock",
    //     "name": "apps_country_lock",
    //     "defaultValue": true,
    //     "disabled": true
    // },
    // {
    //     "id": "apps_country_lock",
    //     "name": "apps_country_lock",
    //     "defaultValue": true,
    //     "disabled": true
    // },
    // {
    //     "id": "apps_country_lock",
    //     "name": "apps_country_lock",
    //     "defaultValue": true,
    //     "disabled": true
    // }
    // ,
    // {
    //     "id": "apps_user_added",
    //     "name": "apps_user_added",
    //     "defaultValue": true,
    //     "disabled": true
    // },
    // {
    //     "id": "apps_date_added",
    //     "name": "apps_date_added",
    //     "defaultValue": true,
    //     "disabled": true
    // },
    // {
    //     "id": "apps_date_updated",
    //     "name": "apps_date_updated",
    //     "defaultValue": true,
    //     "disabled": true
    // },
    // {
    //     "id": "apps_user_updated",
    //     "name": "apps_user_updated",
    //     "defaultValue": true,
    //     "disabled": true
    // }
    // ,
    // {
    //     "id": "apps_cat_name",
    //     "name": "apps_cat_name",
    //     "defaultValue": true,
    //     "disabled": true
    // },
    // {
    //     "id": "apps_cat_image",
    //     "name": "apps_cat_image",
    //     "defaultValue": true,
    //     "disabled": true
    // }


];

const radioCheckBox = [
    {
        "id": "radio_id",
        "name": "radio_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "radio_status",
        "name": "radio_status",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "radio_position",
        "name": "radio_position",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "radio_name_short",
        "name": "radio_name_short",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "radio_name_long",
        "name": "radio_name_long",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "radio_description",
        "name": "radio_description",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "radio_cat_id",
        "name": "radio_cat_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "radio_rpgroup_id",
        "name": "radio_rpgroup_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "radio_url",
        "name": "radio_url",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "radio_ref_id",
        "name": "radio_ref_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "radio_image",
        "name": "radio_image",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "radio_image_wide",
        "name": "radio_image_wide",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "radio_user_added",
        "name": "radio_user_added",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "radio_date_added",
        "name": "radio_date_added",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "radio_date_updated",
        "name": "radio_date_updated",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "radio_date_deleted",
        "name": "radio_date_deleted",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "radio_pre_roll",
        "name": "radio_pre_roll",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "radio_mid_roll",
        "name": "radio_mid_roll",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "radio_post_roll",
        "name": "radio_post_roll",
        "defaultValue": true,
        "disabled": true
    }

];

const customersNpvrHistory = [
    {
        "id": "id",
        "name": "id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "history_dvr_datetime",
        "name": "history_dvr_datetime",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "history_dvr_action",
        "name": "history_dvr_action",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "history_dvr_prog_id",
        "name": "history_dvr_prog_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "history_dvr_channel_id",
        "name": "history_dvr_channel_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "history_dvr_channel_name",
        "name": "history_dvr_channel_name",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "history_dvr_channel_position",
        "name": "history_dvr_channel_position",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "history_dvr_title",
        "name": "history_dvr_title",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "history_dvr_job_id",
        "name": "history_dvr_job_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "history_dvr_rec_type",
        "name": "history_dvr_rec_type",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "history_dvr_rec_policy",
        "name": "history_dvr_rec_policy",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "history_dvr_customer_id",
        "name": "history_dvr_customer_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "history_dvr_customer_mac",
        "name": "history_dvr_customer_mac",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "history_dvr_update_position",
        "name": "history_dvr_update_position",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "history_dvr_status",
        "name": "history_dvr_status",
        "defaultValue": true,
        "disabled": true
    }

];

const customersNpvrRecordings = [
    {
        "id": "rec_id",
        "name": "rec_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "rec_unique_id",
        "name": "rec_unique_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "rec_mac_address",
        "name": "rec_mac_address",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "rec_customerid",
        "name": "rec_customerid",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "job_ref",
        "name": "job_ref",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "rec_xml_channel_id",
        "name": "rec_xml_channel_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "rec_programme_id",
        "name": "rec_programme_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "rec_start_recording",
        "name": "rec_start_recording",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "rec_stop_recording",
        "name": "rec_stop_recording",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "rec_start_recording_unix",
        "name": "rec_stop_recording_unix",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "rec_programme_name",
        "name": "rec_programme_name",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "rec_date_added",
        "name": "rec_date_added",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "rec_programme_description",
        "name": "rec_programme_description",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "rec_status_start",
        "name": "rec_status_start",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "rec_status_stop",
        "name": "rec_status_stop",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "rec_recording_name",
        "name": "rec_recording_name",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "rec_type",
        "name": "rec_type",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "rec_save_policy",
        "name": "rec_save_policy",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "rec_extend_by",
        "name": "rec_extend_by",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "rec_date_updated",
        "name": "rec_date_updated",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "tv_channel_id",
        "name": "tv_channel_id",
        "defaultValue": true,
        "disabled": true
    }

];

const musicCheckbox=[
    {
        "id": "album_id",
        "name": "Album ID",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "album_position",
        "name": "Album Position",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "album_status",
        "name": "Album Status",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "album_name",
        "name": "Album Name",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "album_artist",
        "name": "Album Artist",
        "defaultValue": true,
        "disabled": true
    },
];

const karaokeCheckbox=[
    {
        "id": "karaoke_id",
        "name": "karaoke_id",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "karaoke_status",
        "name": "karaoke_status",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "karaoke_position",
        "name": "karaoke_position",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "karaoke_title",
        "name": "karaoke_title",
        "defaultValue": true,
        "disabled": true
    },
    {
        "id": "karaoke_artist",
        "name": "karaoke_artist",
        "defaultValue": true,
        "disabled": true
    },
];

const easAlertsCheckbox=[
    {
        "id": "EAS_MIDDLEWARE_ID",
        "name": "Middleware ID",
        "defaultValue": true,
        "disabled": false
    },
    {
        "id": "EAS_MIDDLEWARE_DATETIME",
        "name": "DateTime Received",
        "defaultValue": true,
        "disabled": false
    },
    {
        "id": "EAS_LANG",
        "name": "Language",
        "defaultValue": true,
        "disabled": false
    },
    {
        "id": "EAS_NET_VERSION",
        "name": "EAS_NET_VERSION",
        "defaultValue": false,
        "disabled": false
    },
    {
        "id": "EAS_NET_ENCODING",
        "name": "EAS_NET_ENCODING",
        "defaultValue": false,
        "disabled": false
    },
    {
        "id": "EAS_ALERT_EVENT_TYPE",
        "name": "EAS_ALERT_EVENT_TYPE",
        "defaultValue": false,
        "disabled": false
    },
    {
        "id": "EAS_NET_FUNC",
        "name": "EAS_NET_FUNC",
        "defaultValue": false,
        "disabled": false
    },
    {
        "id": "EAS_ID",
        "name": "EAS_ID",
        "defaultValue": true,
        "disabled": false
    },
    {
        "id": "EAS_START_TIME",
        "name": "EAS_START_TIME",
        "defaultValue": true,
        "disabled": false
    },
    {
        "id": "EAS_END_TIME",
        "name": "EAS_END_TIME",
        "defaultValue": true,
        "disabled": false
    },
    {
        "id": "EAS_DURATION",
        "name": "EAS_DURATION",
        "defaultValue": true,
        "disabled": false
    },
    {
        "id": "EAS_GMT_START_TIME_STR",
        "name": "EAS_GMT_START_TIME_STR",
        "defaultValue": true,
        "disabled": false
    },
    {
        "id": "EAS_TYPE",
        "name": "EAS_TYPE",
        "defaultValue": true,
        "disabled": false
    },
    {
        "id": "EAS_TYPE_SEVERITY",
        "name": "EAS_TYPE_SEVERITY",
        "defaultValue": true,
        "disabled": false
    },
    {
        "id": "EAS_ORG",
        "name": "EAS_ORG",
        "defaultValue": true,
        "disabled": false
    },
    {
        "id": "EAS_STATION_ID",
        "name": "EAS_STATION_ID",
        "defaultValue": true,
        "disabled": false
    },
    {
        "id": "EAS_TRANSLATION",
        "name": "EAS_TRANSLATION",
        "defaultValue": true,
        "disabled": false
    },
    {
        "id": "EAS_FIPS",
        "name": "EAS_FIPS",
        "defaultValue": true,
        "disabled": false
    },
    {
        "id": "EAS_VIDEO_START_TIME",
        "name": "EAS_VIDEO_START_TIME",
        "defaultValue": false,
        "disabled": false
    },
    {
        "id": "EAS_VIDEO_STOP_TIME",
        "name": "EAS_VIDEO_STOP_TIME",
        "defaultValue": false,
        "disabled": false
    },
    {
        "id": "EAS_VIDEO_DURATION",
        "name": "EAS_VIDEO_DURATION",
        "defaultValue": false,
        "disabled": false
    },
    {
        "id": "EAS_VIDEO_PERIOD",
        "name": "EAS_VIDEO_PERIOD",
        "defaultValue": false,
        "disabled": false
    },
    {
        "id": "EAS_AUDIO_DURATION",
        "name": "EAS_AUDIO_DURATION",
        "defaultValue": false,
        "disabled": false
    },
    {
        "id": "EAS_NET_EVENT_USERDATA_1",
        "name": "EAS_NET_EVENT_USERDATA_1",
        "defaultValue": false,
        "disabled": false
    },
    {
        "id": "EAS_NET_EVENT_USERDATA_2",
        "name": "EAS_NET_EVENT_USERDATA_2",
        "defaultValue": false,
        "disabled": false
    },
    {
        "id": "EAS_NET_EVENT_USERDATA_3",
        "name": "EAS_NET_EVENT_USERDATA_3",
        "defaultValue": false,
        "disabled": false
    },
    {
        "id": "EAS_NET_EVENT_USERDATA_4",
        "name": "EAS_NET_EVENT_USERDATA_4",
        "defaultValue": false,
        "disabled": false
    },
    {
        "id": "EAS_NET_EVENT_USERDATA_5",
        "name": "EAS_NET_EVENT_USERDATA_5",
        "defaultValue": false,
        "disabled": false
    },
    {
        "id": "EAS_TRANSLATION_LANG",
        "name": "EAS_TRANSLATION_LANG",
        "defaultValue": false,
        "disabled": false
    },
    {
        "id": "EAS_STATION_CFG_ID",
        "name": "EAS_STATION_CFG_ID",
        "defaultValue": false,
        "disabled": false
    },
    {
        "id": "EAS_SRC_STATION_ID",
        "name": "EAS_SRC_STATION_ID",
        "defaultValue": false,
        "disabled": false
    },
    {
        "id": "EAS_ALERT_EVENT_STATION_NAME",
        "name": "EAS_ALERT_EVENT_STATION_NAME",
        "defaultValue": false,
        "disabled": false
    },
    {
        "id": "EAS_NET_SCHEMA_NAME",
        "name": "EAS_NET_SCHEMA_NAME",
        "defaultValue": false,
        "disabled": false
    },
    {
        "id": "EAS_NET_SERVER_IPADDR",
        "name": "EAS_NET_SERVER_IPADDR",
        "defaultValue": false,
        "disabled": false
    },
    {
        "id": "EAS_NET_SERVER_NAME",
        "name": "EAS_NET_SERVER_NAME",
        "defaultValue": false,
        "disabled": false
    },
];


module.exports = {
    deviceCheckBox,
    customerCheckBox,
    channelsCheckBox,
    tvodCheckBox,
    avodCheckBox,
    svodCheckBox,
    vodCategories,
    tvSeries,
    speedTestCheckBox,
    customerVodTicketsCheckBox,
    customerPpvTicketsCheckBox,
    customersPackageStatus,
    applicationsCheckBox,
    radioCheckBox,
    customersNpvrHistory,
    customersNpvrRecordings,
    musicCheckbox,
    karaokeCheckbox,
    easAlertsCheckbox
};
