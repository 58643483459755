import React, {Component} from "react";
import {Redirect} from "react-router";
import Switch from "react-bootstrap-switch";
import axios from "axios/index";
import {
    Grid,
    Row,
    Col,
    FormGroup,
    ControlLabel,
    FormControl,
    InputGroup,
    HelpBlock,
    ButtonGroup
} from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import { css } from "@emotion/core";
import {ScaleLoader} from 'react-spinners';
import {DropDownAppCategory} from "../../components/CustomDropDown/DropDownAppCategory";
import ModalSave from "../../components/CustomModals/ModalSave";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import NotificationSystem from "react-notification-system";
import {style} from "../../variables/Variables";
import Select from "react-select";

const override = css`
    position: fixed;
    left: 50%;
    top: 35%;
    display: block;
    z-index: 1000;
    height: 31px;
    width: 100px;
    `;

const applicationTypeArray = [
    {value: 'APPLICATION', label: 'Application'},
    {value: 'VIDEO', label: 'Video'},
    {value: 'URL', label: 'URL'}
];


class EditApplication extends Component {

    constructor(props) {
        super(props);
        this.state = {

            _notificationSystem: null,

            appArray: [],
            loading: true,
            redirect: false,
            back_button_text: "Cancel",
            showSaveModal: false,

            appRedirect: false,
            appStatus: false,

            apps_id: null,
            apps_status: false,
            apps_position: null,
            apps_name_short: null,
            apps_name_long: null,
            apps_description: null,
            apps_cat_id: null,
            apps_url: null,
            apps_gpgroup_id: null,
            apps_ref_id: null,
            apps_image: null,
            apps_image_wide: null,
            apps_hits: null,
            apps_country_lock: null,
            apps_user_added: null,
            apps_date_added: null,
            apps_url_android: null,
            apps_url_ios: null,

            apps_image_temp: false,
            apps_image_wide_temp: false,

            apps_image_folder_temp: "",
            apps_image_wide_folder_temp: "",

            //VALIDATIONS
            apps_position_validate_state: null,
            apps_name_short_validate_state: null,
            apps_name_long_validate_state: null,

            apps_position_validate_message: "",
            apps_name_short_validate_message: "",
            apps_name_long_validate_message: "",
            app_type: null


        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChildImageSelect = this.handleChildImageSelect.bind(this);
        this.handleChildCategorySelect = this.handleChildCategorySelect.bind(this);

    }

    handleApplicationTypeChange = (data) => {

        this.setState({
            app_type: data === null ? 0 : data.value
        });

    }

    componentDidMount() {

        this.setState({_notificationSystem: this.refs.notificationSystem})

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        const {handle} = this.props.match.params
        const url = process.env.REACT_APP_API_URL + "/Application/" + handle;

        if (handle === 'new') {
            this.setState({loading: false})
            this.getPosition();
        } else {
            axios
                .get(url, axiosConfig)
                .then(res => {

                    if (res.data.error === 401) {
                        this.props.dispatch(userActions.logoutUnauthorized());
                    }

                    const appArray = res.data[0];
                    const loading = false;

                    let appStatus = appArray.apps_status === 0 ? false : true;

                    this.setState({
                        back_button_text: "Back",
                        appArray,
                        appStatus,
                        loading,

                        apps_id: appArray.apps_id,
                        apps_status: appStatus,
                        apps_position: appArray.apps_position,
                        apps_name_short: appArray.apps_name_short,
                        apps_name_long: appArray.apps_name_long,
                        apps_description: appArray.apps_description,
                        apps_cat_id: appArray.apps_cat_id,
                        apps_url: appArray.apps_url,
                        apps_gpgroup_id: appArray.apps_gpgroup_id,
                        apps_ref_id: appArray.apps_ref_id,
                        apps_image: appArray.apps_image,
                        apps_image_wide: appArray.apps_image_wide,
                        apps_hits: appArray.apps_hits,
                        apps_country_lock: appArray.apps_country_lock,
                        apps_user_added: appArray.apps_user_added,
                        apps_date_added: appArray.apps_date_added,
                        apps_url_android: appArray.apps_url_android,
                        apps_url_ios: appArray.apps_url_ios,
                        app_type: appArray.app_type

                    });
                })
                .catch(error => {
                    this.setState({loading: false});
                    console.log(error);
                });
        }


    }

    getPosition() {

        const url = process.env.REACT_APP_API_URL + "/Application/position";

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        axios
            .get(url, axiosConfig)
            .then(res => {

                if (res.data.error === 401) {
                    this.props.dispatch(userActions.logoutUnauthorized());
                }

                const applicationPosition = res.data[0];
                this.setState({
                    apps_position: applicationPosition.ApplicationPosition
                });
            })
            .catch(error => {
                //this.props.dispatch(userActions.logoutUnauthorized());
                console.log(error);
            });

    }

    handleChildCategorySelect(data) {

        this.setState({
            apps_cat_id: data === null ? 0 : data.value
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'statusSwitch' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSwitch(elem, state) {

        if (elem.props.name === 'appStatus') {
            this.setState({appStatus: state})
        }
    }

    submitPosterImage(filename_poster) {

        const imageUploadUrl = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

        if (this.state.apps_image_temp) {

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.apps_image_folder_temp);
            bodyFormData.set('location', "apps_list");
            bodyFormData.set('filename', filename_poster);


            axios.post(imageUploadUrl, bodyFormData)
                .then((res) => {
                    this.setState({apps_image_temp: false})
                })
                .catch((err) => {
                    console.log("apps_image - AXIOS ERROR: ", err);
                })
        }

    }

    submitWideImage(filename_wide) {

        const imageUploadUrl = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

        if (this.state.apps_image_wide_temp) {

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.apps_image_wide_folder_temp);
            bodyFormData.set('location', "apps_list");
            bodyFormData.set('filename', filename_wide);

            axios.post(imageUploadUrl, bodyFormData)
                .then((res) => {
                    this.setState({apps_image_wide_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }


    }

    updateApp() {

        this.setState({loading: true})
        const {handle} = this.props.match.params
        const url = process.env.REACT_APP_API_URL + "/Application/" + handle;

        //RENAME FUNCTIONS START POSTER WIDE
        let filename_wide = ""
        if (this.state.apps_image_wide_temp) {
            let timeUTC = new Date().getTime()
            filename_wide = timeUTC + "_" + this.state.apps_image_wide
            this.setState({apps_image_wide: filename_wide})
            this.submitWideImage(filename_wide);
        } else {
            filename_wide = this.state.apps_image_wide
        }

        //RENAME FUNCTIONS START POSTER NORMAL
        let filename_poster = ""
        if (this.state.apps_image_temp) {
            let timeUTC = new Date().getTime()
            filename_poster = timeUTC + "_" + this.state.apps_image
            this.setState({apps_image: filename_poster})
            this.submitPosterImage(filename_poster);
        } else {
            filename_poster = this.state.apps_image
        }


        let appStatus = this.state.appStatus === true ? 1 : 0;

        // Send a PUT request
        var postData = {
            application: {

                apps_id: this.state.apps_id,
                apps_status: appStatus,
                apps_position: this.state.apps_position,
                apps_name_short: this.state.apps_name_short,
                apps_name_long: this.state.apps_name_long,
                apps_description: this.state.apps_description,
                apps_cat_id: this.state.apps_cat_id,
                apps_url: this.state.apps_url,
                apps_gpgroup_id: this.state.apps_gpgroup_id,
                apps_ref_id: this.state.apps_ref_id,
                apps_image: filename_poster,
                apps_image_wide: filename_wide,
                apps_hits: this.state.apps_hits,
                apps_country_lock: this.state.apps_country_lock,
                apps_user_added: this.state.apps_user_added,
                apps_date_added: this.state.apps_date_added,
                apps_url_android: this.state.apps_url_android,
                apps_url_ios: this.state.apps_url_ios,
                app_type: this.state.app_type
            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        axios.put(url, postData, axiosConfig)
            .then((res) => {

                if (res.data.error === 401) {
                    this.props.dispatch(userActions.logoutUnauthorized());
                }


                if (res.data.state === false) {

                    this.notification("failed")
                    this.setState({saving: false})
                    this.setState({loading: false})

                    this.setState({
                        apps_name_short_validate_message: res.data.apps_name_short_validate_message,
                        apps_name_short_validate_state: res.data.apps_name_short_validate_state,
                        apps_name_long_validate_message: res.data.apps_name_long_validate_message,
                        apps_name_long_validate_state: res.data.apps_name_long_validate_state,
                        apps_position_validate_message: res.data.apps_position_validate_message,
                        apps_position_validate_state: res.data.apps_position_validate_state
                    })

                } else {

                    let application = res.data;

                    this.setState({
                            apps_id: application.apps_id,
                            apps_name_short_validate_message: "",
                            apps_name_short_validate_state: null,
                            apps_name_long_validate_message: "",
                            apps_name_long_validate_state: null,
                            apps_position_validate_message: "",
                            apps_position_validate_state: null
                        }
                    )

                    this.setState({saving: false})
                    this.setState({loading: false})
                    this.notification("success")
                }

            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.setState({loading: false})
                this.notification("failed")
            })

    }

    createApp() {


        this.setState({loading: true})
        const url = process.env.REACT_APP_API_URL + "/Application";

        //RENAME FUNCTIONS START POSTER WIDE
        let filename_wide = ""
        if (this.state.apps_image_wide_temp) {
            let timeUTC = new Date().getTime()
            filename_wide = timeUTC + "_" + this.state.apps_image_wide
            this.setState({apps_image_wide: filename_wide})
            this.submitWideImage(filename_wide);
        } else {
            filename_wide = this.state.apps_image_wide
        }

        //RENAME FUNCTIONS START POSTER NORMAL
        let filename_poster = ""
        if (this.state.apps_image_temp) {
            let timeUTC = new Date().getTime()
            filename_poster = timeUTC + "_" + this.state.apps_image
            this.setState({apps_image: filename_poster})
            this.submitPosterImage(filename_poster);
        } else {
            filename_poster = this.state.apps_image
        }


        let appStatus = this.state.appStatus === true ? 1 : 0;

        // Send a PUT request
        var postData = {
            application: {

                apps_status: appStatus,
                apps_position: this.state.apps_position,
                apps_name_short: this.state.apps_name_short,
                apps_name_long: this.state.apps_name_long,
                apps_description: this.state.apps_description,
                apps_cat_id: this.state.apps_cat_id,
                apps_url: this.state.apps_url,
                apps_gpgroup_id: this.state.apps_gpgroup_id,
                apps_ref_id: this.state.apps_ref_id,
                apps_image: filename_poster,
                apps_image_wide: filename_wide,
                apps_hits: this.state.apps_hits,
                apps_country_lock: this.state.apps_country_lock,
                apps_user_added: this.state.apps_user_added,
                apps_date_added: this.state.apps_date_added,
                apps_url_android: this.state.apps_url_android,
                apps_url_ios: this.state.apps_url_ios,
                app_type: this.state.app_type
            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        axios.post(url, postData, axiosConfig)
            .then((res) => {

                if (res.data.error === 401) {
                    this.props.dispatch(userActions.logoutUnauthorized());
                }

                //const application = res.data;
                this.setState({loading: false})

                if (res.data.state === false) {
                    this.notification("failed")
                    this.setState({
                        apps_name_short_validate_message: res.data.apps_name_short_validate_message,
                        apps_name_short_validate_state: res.data.apps_name_short_validate_state,
                        apps_name_long_validate_message: res.data.apps_name_long_validate_message,
                        apps_name_long_validate_state: res.data.apps_name_long_validate_state,
                        apps_position_validate_message: res.data.apps_position_validate_message,
                        apps_position_validate_state: res.data.apps_position_validate_state
                    })
                } else {
                    const application = res.data;
                    this.setState({
                            apps_id: application.apps_id,
                            apps_name_short_validate_message: "",
                            apps_name_short_validate_state: null,
                            apps_name_long_validate_message: "",
                            apps_name_long_validate_state: null,
                            apps_position_validate_message: "",
                            apps_position_validate_state: null
                        }
                    )
                    this.setAppRedirect();
                    this.notification("success")
                }


            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.setState({loading: false})
                this.notification("failed")
            })

    }

    handleChildImageSelect(folder, filename, imagePosition) {

        if (imagePosition === "apps_image") {
            this.setState({
                apps_image_temp: filename !== null,
                apps_image: filename,
                apps_image_folder_temp: folder
            });
        }
        if (imagePosition === "apps_image_wide") {
            this.setState({
                apps_image_wide_temp: filename !== null,
                apps_image_wide: filename,
                apps_image_wide_folder_temp: folder
            });
        }


    }

    cancelAppEdit() {
        this.setRedirect();
    }

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    setAppRedirect = () => {
        this.setState({
            appRedirect: true,
            back_button_text: "Back"
        })
    }

    renderRedirect = () => {

        let path = "/applications";
        let pathApp = "/edit-app/" + this.state.apps_id;

        if (this.state.redirect) {
            return <Redirect push to={path}/>
        }

        if (this.state.appRedirect) {
            this.setState({
                redirect: false,
                appRedirect: false
            })
            return <Redirect push to={pathApp}/>
        }

    }

    setSaveModal = (value, action) => {

        this.setState({
            showSaveModal: value
        })

        if (action === "save") {
            this.setState({saving: true})

            const {handle} = this.props.match.params

            if (handle === 'new') {
                this.createApp();
            } else {
                this.updateApp();
            }
        }
    }

    renderSaveModal = () => {

        if (this.state.showSaveModal) {
            return <ModalSave
                headerMessage={"Application: "}
                bodyMessage={"this action will save all changes, click close to cancel saving"}
                handlerFromParent={this.setSaveModal}
                handlerID={this.state.apps_id}
            />
        }
    }

    notification(status) {

        if (status === "success") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>Application has been updated</b>
                    </div>
                ),
                level: 'success',
                position: 'tr',
                autoDismiss: 5,
            });
        }

        if (status === "failed") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>failed to update application</b>
                    </div>
                ),
                level: 'error',
                position: 'tr',
                autoDismiss: 10,
            });
        }

    }

    render() {
        return (
            <div className="main-content">
                <NotificationSystem ref="notificationSystem" style={style}/>

                {this.renderRedirect()}
                {this.renderSaveModal()}

                    <div className="sweet-loader">
                        <ScaleLoader
                            css={override}
                            color={'#797979'}
                            loading={this.state.loading}
                        />
                    </div>

                    <Grid fluid>
                        <Row>
                            <Col md={8}>
                                <Card
                                    content={
                                        <div>
                                            <h5>{this.props.t('application_edit.main_title', {framework: "react-i18next"})}</h5>
                                            <legend/>
                                            <Grid fluid>
                                                <Col md={12}>
                                                    <Row>
                                                        <FormGroup>
                                                            <Switch
                                                                onChange={(el, state) => this.handleSwitch(el, state)}
                                                                name='appStatus'
                                                                disabled={this.state.loading}
                                                                value={this.state.appStatus}
                                                                defaultValue={false}
                                                            />
                                                        </FormGroup>
                                                    </Row>
                                                    <Row>
                                                        <FormGroup controlId="formValidationPosition"
                                                                   validationState={this.state.apps_position_validate_state}>
                                                            <ControlLabel>{this.props.t('application_edit.position', {framework: "react-i18next"})}</ControlLabel>
                                                            <FormControl placeholder={this.props.t('application_edit.position_placeholder', {framework: "react-i18next"})}
                                                                         type="number"
                                                                         name="apps_position"
                                                                         disabled={this.state.loading}
                                                                         onChange={this.handleInputChange}
                                                                         value={this.state.apps_position || ""}
                                                            />
                                                            <HelpBlock>{this.state.apps_position_validate_message}</HelpBlock>
                                                        </FormGroup>
                                                    </Row>
                                                    <Row>
                                                        <FormGroup controlId="formValidationShortName"
                                                                   validationState={this.state.apps_name_short_validate_state}>
                                                            <ControlLabel>{this.props.t('application_edit.name_short', {framework: "react-i18next"})}</ControlLabel>
                                                            <FormControl placeholder={this.props.t('application_edit.name_short_placeholder', {framework: "react-i18next"})}
                                                                         type="text"
                                                                         name="apps_name_short"
                                                                         disabled={this.state.loading}
                                                                         onChange={this.handleInputChange}
                                                                         value={this.state.apps_name_short || ""}
                                                            />
                                                            <HelpBlock>{this.state.apps_name_short_validate_message}</HelpBlock>
                                                        </FormGroup>
                                                    </Row>
                                                    <Row>
                                                        <FormGroup controlId="formValidationLongName"
                                                                   validationState={this.state.apps_name_long_validate_state}>
                                                            <ControlLabel>{this.props.t('application_edit.name_long', {framework: "react-i18next"})}</ControlLabel>
                                                            <FormControl placeholder={this.props.t('application_edit.name_long_placeholder', {framework: "react-i18next"})}
                                                                         type="text"
                                                                         name="apps_name_long"
                                                                         disabled={this.state.loading}
                                                                         onChange={this.handleInputChange}
                                                                         value={this.state.apps_name_long || ""}
                                                            />
                                                            <HelpBlock>{this.state.apps_name_long_validate_message}</HelpBlock>
                                                        </FormGroup>
                                                    </Row>
                                                    <Row>
                                                        <FormGroup>
                                                            <ControlLabel>{this.props.t('application_edit.description', {framework: "react-i18next"})}</ControlLabel>
                                                            <FormControl componentClass="textarea"
                                                                         placeholder={this.props.t('application_edit.description_placeholder', {framework: "react-i18next"})}
                                                                         type="text"
                                                                         name="apps_description"
                                                                         disabled={this.state.loading}
                                                                         rows={4}
                                                                         onChange={this.handleInputChange}
                                                                         value={this.state.apps_description || ""}
                                                            />
                                                        </FormGroup>
                                                    </Row>


                                                    <Row>
                                                        <FormGroup>
                                                            <ControlLabel>{this.props.t('application_edit.url', {framework: "react-i18next"})}</ControlLabel>
                                                            <InputGroup>
                                                                <InputGroup.Addon style={{backgroundColor: "#383838"}}>
                                                                    <i className="fa fa-link" style={{color: "#ffffff"}} />
                                                                </InputGroup.Addon>
                                                                <FormControl placeholder={this.props.t('application_edit.url_placeholder', {framework: "react-i18next"})}
                                                                             type="text"
                                                                             name="apps_url"
                                                                             disabled={this.state.loading}
                                                                             onChange={this.handleInputChange}
                                                                             value={this.state.apps_url || ""}
                                                                />
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </Row>

                                                    <Row>
                                                        <FormGroup>
                                                            <ControlLabel>{this.props.t('application_edit.android_url', {framework: "react-i18next"})}</ControlLabel>
                                                            <InputGroup>
                                                                <InputGroup.Addon style={{backgroundColor: "#383838"}}>
                                                                    <i className="fa fa-android" style={{color: "#ffffff"}}/>
                                                                </InputGroup.Addon>
                                                                <FormControl placeholder={this.props.t('application_edit.android_url_placeholder', {framework: "react-i18next"})}
                                                                             type="text"
                                                                             name="apps_url_android"
                                                                             disabled={this.state.loading}
                                                                             onChange={this.handleInputChange}
                                                                             value={this.state.apps_url_android || ""}
                                                                />
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </Row>
                                                    <Row>
                                                        <FormGroup>
                                                            <ControlLabel>{this.props.t('application_edit.ios_url', {framework: "react-i18next"})}</ControlLabel>
                                                            <InputGroup>
                                                                <InputGroup.Addon style={{backgroundColor: "#383838"}}>
                                                                    <i className="fa fa-apple" style={{color: "#ffffff"}}/>
                                                                </InputGroup.Addon>
                                                                <FormControl placeholder={this.props.t('application_edit.ios_url_placeholder', {framework: "react-i18next"})}
                                                                             type="text"
                                                                             name="apps_url_ios"
                                                                             disabled={this.state.loading}
                                                                             onChange={this.handleInputChange}
                                                                             value={this.state.apps_url_ios || ""}
                                                                />
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </Row>

                                                    <Row>
                                                        <FormGroup>
                                                            <ControlLabel>{this.props.t('application_edit.category', {framework: "react-i18next"})}</ControlLabel>
                                                            <DropDownAppCategory
                                                                categorySelected={this.state.apps_cat_id}
                                                                name='categorySelect'
                                                                disabled={this.state.loading}
                                                                handlerFromParent={this.handleChildCategorySelect}
                                                            />
                                                        </FormGroup>
                                                    </Row>

                                                    <Row>
                                                        <FormGroup>
                                                            <ControlLabel>Type</ControlLabel>
                                                            <Select
                                                                value={this.state.app_type}
                                                                onChange={this.handleApplicationTypeChange}
                                                                options={applicationTypeArray}
                                                                disabled={this.state.loading}
                                                            />
                                                        </FormGroup>
                                                    </Row>

                                                </Col>
                                            </Grid>
                                        </div>
                                    }
                                />
                            </Col>

                            <Col md={4}>
                                <Row>
                                    <Card
                                        tableFullWidth
                                        content={
                                            <div id="out-test" className="text-center center center-block">
                                                <div id="test" style={{textAlignLast: "center"}}>
                                                    <ButtonGroup aria-label="Basic example">
                                                            <Button
                                                                onClick={() => this.setState({showSaveModal: true})}
                                                                bsStyle="info"
                                                                fill wd
                                                                //type="submit"
                                                                disabled={this.state.loading}
                                                                name={"saveButton"}>
                                                                {this.state.loading ? 'Loading...' : this.props.t('global.save', {framework: "react-i18next"})}
                                                            </Button>
                                                            <Button bsStyle="default" fill wd
                                                                    name={"cancelButton"}
                                                                    disabled={this.state.loading}
                                                                    onClick={this.cancelAppEdit.bind(this)}>
                                                                {this.state.back_button_text}
                                                            </Button>
                                                    </ButtonGroup>
                                                </div>
                                            </div>

                                        }
                                    />
                                </Row>
                                <Row>
                                    <Card
                                        // title={<legend>Assets</legend>}
                                        // tableFullWidth
                                        content={
                                            <div>
                                                <h5>Assets</h5>
                                                <legend/>
                                                <Grid fluid>
                                                    <Col md={12}>
                                                        <Row>
                                                            <FormGroup>
                                                                <ControlLabel>{this.props.t('application_edit.image_normal', {framework: "react-i18next"})}</ControlLabel>
                                                                <ImageUpload
                                                                    imageName={this.state.apps_image}
                                                                    imageType="apps_list"
                                                                    imagePosition={"apps_image"}
                                                                    imageTemp={this.state.apps_image_temp}
                                                                    imageFolderTemp={this.state.apps_image_folder_temp}
                                                                    handlerFromParent={this.handleChildImageSelect}
                                                                />
                                                            </FormGroup>
                                                        </Row>
                                                        <Row>
                                                            <FormGroup>
                                                                <ControlLabel>{this.props.t('application_edit.image_wide', {framework: "react-i18next"})}</ControlLabel>
                                                                <ImageUpload
                                                                    imageName={this.state.apps_image_wide}
                                                                    imageType="apps_list"
                                                                    imagePosition={"apps_image_wide"}
                                                                    imageTemp={this.state.apps_image_wide_temp}
                                                                    imageFolderTemp={this.state.apps_image_wide_folder_temp}
                                                                    handlerFromParent={this.handleChildImageSelect}
                                                                />
                                                            </FormGroup>
                                                        </Row>

                                                    </Col>
                                                </Grid>
                                            </div>
                                        }
                                    />
                                </Row>
                            </Col>

                        </Row>
                    </Grid>

            </div>
        );
    }
}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedEditApplication = connect(mapStateToProps)(EditApplication);
const connectedEditApplicationTrans = withNamespaces('common')(connectedEditApplication);
export {connectedEditApplicationTrans as EditApplication};
